import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7RGiku82yW5gVZK6mbCGcfCzAwwdeDjc",
  authDomain: "superbcamping.com",
  // authDomain: "superbcamping-213a8.firebaseapp.com",
  databaseURL: "https://superbcamping-213a8.firebaseio.com",
  projectId: "superbcamping-213a8",
  storageBucket: "superbcamping-213a8.appspot.com",
  messagingSenderId: "154106496923",
  appId: "1:154106496923:web:afac19b1f14abb344dc33e",
  measurementId: "G-HME90KXSGH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
