import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Alert,
  Button,
  Stack,
  Spinner,
} from "react-bootstrap";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import useSessionStorageState from "use-session-storage-state";
import QRCode from "qrcode.react";

// Components
import Heading from "../../shared/Heading";

// Services
import { domainMain, limitations } from "../../../other/Constants";
import { listenToBooking } from "../../../services/Firebase";
import {
  getHomeTitle,
  getHomeAddressString,
  getUserFacingPrice,
  getLimitationIcon,
  BookingStatus,
} from "../../../other/Utilities";

function BookingSummaryView({ booking }) {
  const { t, i18n } = useTranslation();

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  function getUserFacingDate(date) {
    return date.toDate().toLocaleDateString(i18n.resolvedLanguage);
  }

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col md="auto">
          <p
            style={{
              height: 120,
              width: 160,
              borderRadius: 10,
              backgroundColor: "lightgray",
              textAlign: "center",
            }}
          >
            <Image
              src={booking && booking.home.imageLinks[0]}
              style={{
                height: 120,
                borderRadius: 10,
                objectFit: "cover",
                width: "100%",
              }}
            />
          </p>
        </Col>
        <Col>
          <h5>{booking && getHomeTitle(t, booking.home)}</h5>
          <p style={{ color: "gray" }}>
            {booking && getHomeAddressString(booking.home.address)}
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: -10, marginBottom: 5 }}>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ fontWeight: "bold" }}>{t("Check-In")}</p>
          <p>{booking && getUserFacingDate(booking.startDate)}</p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>{t("Check-Out")}</p>
          <p>{booking && getUserFacingDate(booking.endDate)}</p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>{t("Guests")}</p>
          <p>{booking && booking.adults + booking.children}</p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>{t("Vehicle")}</p>
          <Stack direction="horizontal" gap={3}>
            {booking &&
              limitations.map(
                (limitation) =>
                  booking.vehicle[limitation] && getLimitationIcon(limitation)
              )}
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>{t("Details to the Price")}</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {booking &&
              getUserFacingPrice(
                booking.home.price,
                i18n.resolvedLanguage
              )}{" "}
            {currency} x {booking && booking.nights} {t("Nights")}
          </p>
        </Col>
        <Col>
          <b style={{ float: "right" }}>
            {booking &&
              getUserFacingPrice(booking.amount, i18n.resolvedLanguage)}{" "}
            {currency}
          </b>
        </Col>
      </Row>
    </Container>
  );
}

function BookingStatusAlert({ booking }) {
  const { t, i18n } = useTranslation();
  if (!booking) return null;
  if (
    booking.status === BookingStatus.new ||
    booking.status === BookingStatus.created ||
    booking.status === BookingStatus.requires_action
  ) {
    return (
      <Alert variant="secondary">
        <Spinner animation="border" size="sm" />
      </Alert>
    );
  } else if (booking.status === BookingStatus.succeeded) {
    return (
      <Alert variant="success">
        {t("Booked successfully on the %@.", {
          val: booking.startDate
            .toDate()
            .toLocaleDateString(i18n.resolvedLanguage),
        })}
      </Alert>
    );
  } else {
    // TODO: Write full text for each status.
    return <Alert variant="danger">{booking.status}</Alert>;
  }
}

function QRCodeSection({ booking }) {
  const { t } = useTranslation();

  if (!booking || booking.status !== BookingStatus.succeeded) return null;
  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("QR-Code")}</h4>
          <p>{t("This code can be used by the host to verify the booking.")}</p>
          <p>
            {t(
              "Just scan the code with the camera of your phone and you will be redirected to the verification page."
            )}
          </p>
        </Col>
        <Col md="auto">
          <QRCode
            value={`${domainMain}/account/verify-booking/${booking.id}`}
            size={140}
          />
        </Col>
      </Row>
    </Container>
  );
}

function BookAgainSection({ booking }) {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Book again?")}</h4>
          <p>
            {t(
              "The stay was to great you want to book again? Show me the accommodation again!"
            )}
          </p>
          <Link to={`/explore/${booking && booking.home.id}`}>
            <Button variant="primary">{t("Show accommodation")}</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

function ContactDetailsSection({ booking }) {
  const { t } = useTranslation();

  if (!booking || !booking.home.shareContactDetails) return null;
  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Contact details")}</h4>
          <p>
            {t(
              "You can contact the owner of this property under the following address:"
            )}
          </p>
          <p>
            {t("Name")}:{" "}
            {booking.home.contactDetails && booking.home.contactDetails.name}
          </p>
          <p>
            {t("Email")}:{" "}
            {booking.home.contactDetails && booking.home.contactDetails.email}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function BookingView({ t }) {
  let { bookingId } = useParams();
  let [searchParams] = useSearchParams();
  const [bookingUnsubscribe, setBookingUnsubscribe] = useState(null);
  const [booking, setBooking] = useState(null);

  // TODO: Find better place for this
  const [, setStartDate] = useSessionStorageState("startDate", {
    defaultValue: null,
  });
  const [, setEndDate] = useSessionStorageState("endDate", {
    defaultValue: null,
  });
  const [, setAdults] = useSessionStorageState("adults", {
    defaultValue: 2,
  });
  const [, setChildren] = useSessionStorageState("children", {
    defaultValue: 0,
  });

  useEffect(() => {
    // Unsubscribe from any booking listeners from before in case of bookingId changes
    bookingUnsubscribe && bookingUnsubscribe();
    // Subscribe to booking
    bookingId && setBookingUnsubscribe(listenToBooking(bookingId, setBooking));
  }, [bookingId]);

  useEffect(() => {
    return () => {
      // Unsubscribe from booking listener when leaving the page
      bookingUnsubscribe && bookingUnsubscribe();
    };
  }, []);

  function resetLocalBookingData() {
    setStartDate(null);
    setEndDate(null);
    setAdults(2);
    setChildren(0);
  }

  useEffect(() => {
    if (searchParams) {
      var searchValues = Array.from(searchParams.entries());
      searchValues && searchValues.length >= 3 && resetLocalBookingData();
    }
  }, [searchParams]);

  return (
    <Container className="mainContainer">
      <Heading
        text={booking && getHomeTitle(t, booking.home)}
        backButton={true}
      />
      <Row style={{ marginTop: -10 }}>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <BookingStatusAlert booking={booking} />
          <QRCodeSection booking={booking} />
          <ContactDetailsSection booking={booking} />
        </Col>
        <Col>
          <BookingSummaryView booking={booking} />
          <BookAgainSection booking={booking} />
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(BookingView);

export { BookingSummaryView, BookingStatusAlert };
