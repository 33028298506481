import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";
import HomeForm from "./HomeForm";

// Services
import {
  updateHome,
  uploadHomeImages,
  getHome,
} from "../../../services/Firebase";
import { isConnectedAccount, getHomeTitle } from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function EditHome({ t }) {
  const navigate = useNavigate();
  const { homeId } = useParams();
  const userData = useContext(UserContext);

  const [home, setHome] = useState(null);
  const [images, setImages] = useState([]);

  const [progress, setProgress] = useState(0);
  const [submittingInProgress, setSubmittingInProgress] = useState(false);

  useEffect(() => {
    homeId && getHome(homeId, setHome);
  }, [homeId]);

  const sendForm = async (homeNew, imagesNew) => {
    await updateHome(homeId, homeNew);
    imagesNew.length > 0 &&
      (await uploadHomeImages(homeId, imagesNew, setProgress));
    navigate("/account/home-dashboard/" + homeId);
  };

  return (
    <Container
      className="mainContainer"
      style={{ marginBottom: 40, maxWidth: 800, minWidth: 350 }}
    >
      <Heading text={home && getHomeTitle(t, home)} backButton={true} />
      <Row>
        <Col>
          <hr style={{ marginTop: -5 }} />
        </Col>
      </Row>
      <HomeForm
        editMode={true}
        progress={progress}
        submittingInProgress={submittingInProgress}
        setSubmittingInProgress={setSubmittingInProgress}
        isConnectedAccount={userData ? isConnectedAccount(userData) : false}
        home={home}
        images={images}
        setImages={setImages}
        sendForm={sendForm}
      />
    </Container>
  );
}

export default withTranslation()(EditHome);
