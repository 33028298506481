import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Components
import LanguageDropdown from "./LanguageDropdown";
import { socialMediaList } from "../../other/Constants";

// Images
import aws_logo from "../../images/aws_logo.jpg";
import CurrencyDropdown from "./CurrencyDropdown";

function Footer({ t }) {
  return (
    <Container
      fluid
      style={{
        background: "var(--light-gray)",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <Row>
        <Col>
          <Container style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Row>
              <Col>
                <h6>{t("Legal")}</h6>
                <p>
                  <Link to={"/terms-of-service"} className="plainLink">
                    {t("Terms of Service")}
                  </Link>
                </p>
                <p>
                  <Link to={"/privacy-policy"} className="plainLink">
                    {t("Privacy Policy")}
                  </Link>
                </p>
              </Col>
              <Col>
                <h6>{t("About us")}</h6>
                <p>
                  <Link to={"/impressum"} className="plainLink">
                    {t("Impressum")}
                  </Link>
                </p>
                <p>
                  <Link to={"/faqs"} className="plainLink">
                    {t("FAQs")}
                  </Link>
                </p>
              </Col>
              <Col>
                <h6>{t("Language")}</h6>
                <div style={{ marginTop: 8 }}>
                  <LanguageDropdown />
                </div>
                <div style={{ marginTop: 16 }}>
                  <CurrencyDropdown />
                </div>
              </Col>
              <Col>
                <h6>{t("Partner")}</h6>
                <p>
                  <Image src={aws_logo} width="180px" />
                </p>
              </Col>
            </Row>
            <Row>
              <div style={{ textAlign: "center", marginTop: 20 }}>
                {socialMediaList.map((socialMedia) => {
                  return (
                    <a
                      key={socialMedia[0]}
                      href={socialMedia[1]}
                      className="plainLink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={socialMedia[2]}
                        style={{ fontSize: 25, marginRight: 10 }}
                      ></i>
                    </a>
                  );
                })}
              </div>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(Footer);
