import React, { useState } from "react";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { START_DATE } from "react-dates/constants";
import moment from "moment";
import "moment/locale/de";
import { withTranslation } from "react-i18next";

// Services
import { diffDays } from "../../other/Utilities";

function DatePicker({
  t,
  i18n,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  blockedDates,
  maxNights,
  numberOfMonths,
}) {
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  function dateRangeIsShorterThanMaxNights(startDate, endDate) {
    if (startDate == null || endDate == null) {
      return true;
    } else {
      return diffDays(startDate, endDate) <= maxNights;
    }
  }

  function dateRangeContainsBlockedDate(startDate, endDate) {
    return blockedDates.some(
      (date) =>
        moment(date.toDate()) >= startDate && moment(date.toDate()) < endDate
    );
  }

  function dateRangeIsValid(startDate, endDate) {
    return (
      !dateRangeContainsBlockedDate(startDate, endDate) &&
      dateRangeIsShorterThanMaxNights(startDate, endDate)
    );
  }

  const handleDatesChange = ({ startDate, endDate }) => {
    if (dateRangeIsValid(startDate, endDate)) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  function getCalendarTitle() {
    if (startDate == null) {
      return t("Choose a Check-In Date");
    } else if (endDate == null) {
      return t("Choose a Check-Out Date");
    } else {
      return t("Stay for ") + diffDays(startDate, endDate) + t(" nights(s)");
    }
  }

  function isBlocked(day) {
    return blockedDates.some((date) =>
      day.isSame(moment(date.toDate()), "day")
    );
  }

  return (
    <>
      <h4>{getCalendarTitle()}</h4>
      <p style={{ color: "gray" }}>
        {startDate == null
          ? t("Enter your travel dates to see the total price.")
          : t("Maximum allowed nights") + maxNights}
      </p>
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        maxDate={moment().add(1, "years")}
        minDate={moment().subtract(1, "years")}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput || START_DATE}
        onFocusChange={(focusedInput) => {
          if (dateRangeIsValid(startDate, endDate))
            setFocusedInput(focusedInput);
        }}
        numberOfMonths={numberOfMonths}
        minimumNights={1}
        isOutsideRange={(day) => day.isBefore(moment().add(1, "days"))}
        hideKeyboardShortcutsPanel={true}
        isDayBlocked={isBlocked}
        renderMonthElement={({ month }) =>
          moment(month).locale(i18n.resolvedLanguage).format("MMMM YYYY")
        }
      />
    </>
  );
}

export default withTranslation()(DatePicker);
