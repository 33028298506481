import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { withTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import "./BookingsChart.css";

// Services
import { getHomeTitle, getUserFacingPrice } from "../../../other/Utilities";

function BookingsChart({ t, i18n, bookings, userData, ownHomes }) {
  // Define Range of the Chart
  const userRegisterDate = userData.registrationDate.toDate();
  var startDate = new Date(
    userRegisterDate.getFullYear(),
    userRegisterDate.getMonth(),
    0
  );
  var today = new Date();
  var endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const domain = [() => startDate.getTime(), () => endDate.getTime()];

  // Prepare data for displaying
  const data = () => {
    var tempList = [];
    for (let i = 0; i < bookings.length; i++) {
      const booking = bookings[i];
      var temp = {
        date: booking.bookingDate.toDate(),
        nights: booking.nights,
        home_name: getHomeTitle(t, booking.home),
        total_price: getUserFacingPrice(booking.amount, i18n.resolvedLanguage),
      };
      temp[booking.home.id] = getUserFacingPrice(
        booking.amount,
        i18n.resolvedLanguage
      );
      tempList.push(temp);
    }
    return tempList;
  };

  const dateFormatter = (date) => {
    return new Date(date).toLocaleDateString(i18n.resolvedLanguage);
  };

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${dateFormatter(label)}`}</p>
          <p className="intro">{`${payload[0].payload.home_name}`}</p>
          <p className="desc">
            {t("Nights")}: {`${payload[0].payload.nights}`}
          </p>
          <p className="desc">
            {t("Total")}: {`${payload[0].payload.total_price}`} {currency}
          </p>
        </div>
      );
    }

    return null;
  };

  const legendFormatter = (value, entry, index) => {
    return getHomeTitle(
      t,
      ownHomes.find((home) => home.id === value)
    );
  };

  // For max. 24 Homes
  const colorTemplate = [
    "#1890FF",
    "#66B5FF",
    "#41D9C7",
    "#2FC25B",
    "#6EDB8F",
    "#9AE65C",
    "#FACC14",
    "#E6965C",
    "#57AD71",
    "#223273",
    "#738AE6",
    "#7564CC",
    "#8543E0",
    "#A877ED",
    "#5C8EE6",
    "#13C2C2",
    "#70E0E0",
    "#5CA3E6",
    "#3436C7",
    "#8082FF",
    "#DD81E6",
    "#F04864",
    "#FA7D92",
    "#D598D9",
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={400}
        data={data()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="number"
          tickFormatter={dateFormatter}
          domain={domain}
        />
        <YAxis
          label={{
            value: t("Price %@", { val: currency }),
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={legendFormatter} />
        {ownHomes.map((entry, index) => (
          <Bar
            key={entry.id}
            dataKey={entry.id}
            stackId="a"
            fill={colorTemplate[index < colorTemplate.length ? index : 0]}
            barSize={100}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default withTranslation()(BookingsChart);
