import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { withTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";
import UserForm from "./UserForm";

// Services
import { auth } from "../../../services/Firebase";

function RegisterView({ t }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect the user to the explore page when logged in.
    if (user) navigate("/explore");
  }, [user]);

  return (
    <Container className="mainContainer" style={{ maxWidth: 600 }}>
      <Heading text={"Register"} />
      <Row>
        <Col>
          <UserForm editMode={false} />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <p style={{ color: "gray" }}>
            {t("You already have an account?")}{" "}
            <Link to={"/account/login"}>{t("Login")}</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(RegisterView);
