import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import { createCustomEqual } from "fast-equals";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useTranslation } from "react-i18next";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
import "./Map.css";

// Services
import { googleAPIKey } from "../../other/Keys";
import {
  styles,
  getHomeTitle,
  getHomeAddressString,
} from "../../other/Utilities";

function MapInfoWindowContent({ home }) {
  const { t } = useTranslation();
  return (
    <a href={"explore/" + home.id} className="plainLink">
      <Container style={styles.container} fluid>
        <Row style={styles.row}>
          <Col style={styles.col}>
            <Image
              src={home.thumbnailLinks.sort()[0]}
              style={{ height: 180, width: "100%", objectFit: "cover" }}
            />
          </Col>
        </Row>
        <Row style={styles.row}>
          <Col style={{ marginTop: 10 }}>
            <h6>{getHomeTitle(t, home)}</h6>
            <p>
              <i className="bi-geo-fill"></i>{" "}
              {getHomeAddressString(home.address)}
            </p>
          </Col>
        </Row>
      </Container>
    </a>
  );
}

const MapComponent = ({
  map,
  setMap,
  infoWindow,
  setInfoWindow,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef();
  const google = window.google;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new google.maps.Map(ref.current, {
          fullscreenControl: false,
          streetViewControl: false,
          gestureHandling: "greedy",
        })
      );
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  useEffect(() => {
    if (map) {
      // Geolocation
      const locationButton = document.createElement("button");
      locationButton.textContent = "";
      locationButton.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        locationButton
      );
      locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              map.setCenter(pos);
            },
            () => {}
          );
        }
      });
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      // Marker Overlay to give all marker images a css tag for customization
      var myoverlay = new google.maps.OverlayView();
      myoverlay.draw = function () {
        this.getPanes().markerLayer.id = "markerLayer";
      };
      myoverlay.setMap(map);

      //Initialize InfoWindow
      setInfoWindow(
        new google.maps.InfoWindow({ maxWidth: 270, minWidth: 270 })
      );
    }
  }, [map]);

  useEffect(() => {
    if (infoWindow) {
      // Close InfoWindow when clicked outside
      google.maps.event.addListener(map, "click", function (event) {
        infoWindow.close();
      });
    }
  }, [infoWindow]);

  return (
    <>
      <div ref={ref} id="map" style={{ width: "100%", height: "100%" }} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(
      new window.google.maps.LatLng(b)
    );
  }
  // TODO extend to other types
  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  function addListenerFunc(contentString, marker) {
    let map = options.map;
    let infoWindow = options.infoWindow;
    infoWindow.setContent(contentString);
    infoWindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });
  }

  useEffect(() => {
    if (marker && options.infoWindow) {
      let contentString = ReactDOMServer.renderToString(
        <MapInfoWindowContent home={options.home} />
      );
      marker.addListener("click", () => addListenerFunc(contentString, marker));
    }
  }, [marker, options]);

  return null;
};

const Polygon = (options) => {
  const [polygon, setPolygon] = useState();
  const google = window.google;

  useEffect(() => {
    if (!polygon) {
      setPolygon(new google.maps.Polygon());
    }

    // remove marker from map on unmount
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon]);

  useEffect(() => {
    if (polygon) {
      polygon.setOptions(options);
    }
  }, [polygon, options]);

  return null;
};

function Map({ homes, zoomedMap }) {
  const render = (status) => {
    if (status === Status.LOADING) return <h5>{status} ..</h5>;
    if (status === Status.FAILURE) return <h5>{status} ...</h5>;
    return null;
  };
  const [map, setMap] = useState();
  const [infoWindow, setInfoWindow] = useState();
  const [zoom, setZoom] = useState(7);
  const [center, setCenter] = useState({
    lat: 48.14,
    lng: 14.03,
  });
  const google = window.google;

  const onIdleMap = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  useEffect(() => {
    if (zoomedMap && homes.length > 0) {
      setCenter({
        lat: homes[0].address.location.latitude,
        lng: homes[0].address.location.longitude,
      });
      setZoom(17);
    }
  }, [homes]);

  return (
    <Wrapper apiKey={googleAPIKey} render={render}>
      <MapComponent
        map={map}
        setMap={setMap}
        infoWindow={infoWindow}
        setInfoWindow={setInfoWindow}
        center={center}
        onIdle={onIdleMap}
        zoom={zoom}
        style={{ flexGrow: "1", height: "100%" }}
        clickableIcons={false}
      >
        {google !== undefined &&
          homes.map((home, index) => (
            <Marker
              key={index}
              position={{
                lat: home.address.location.latitude,
                lng: home.address.location.longitude,
              }}
              icon={{
                url: home.thumbnailLinks.sort()[0],
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 20),
              }}
              optimized={false}
              infoWindow={infoWindow}
              map={map}
              home={home}
            />
          ))}
        {zoom > 12 &&
          homes.map((home, index) => (
            <Polygon
              key={index}
              path={home.address.polygon}
              strokeColor={"#FF0000"}
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor={"#FF0000"}
              fillOpacity={0.35}
            />
          ))}
      </MapComponent>
    </Wrapper>
  );
}

export default React.memo(Map);
