import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Services
import { updateUserData } from "../../../services/Firebase";
import { UserContext } from "../../../other/Contexts";

function UnsubscribeEmails({ t }) {
  const userData = useContext(UserContext);
  const navigate = useNavigate();

  const handleUnsubscribe = async () => {
    await updateUserData(userData.uid, {
      emailSubscription: false,
    });
    navigate("/");
  };

  return (
    <Container className="mainContainer">
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h2>{t("Unsubscribe Emails")}</h2>
          <p>{t("Do you want to unsubscribe from all emails?")}</p>
          <p>
            <Button
              variant="secondary"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("Cancel")}
            </Button>{" "}
            <Button
              variant="primary"
              onClick={() => {
                handleUnsubscribe();
              }}
            >
              {t("Unsubscribe")}
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(UnsubscribeEmails);
