import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Services
import { logInWithEmailAndPassword } from "../../../services/Firebase";

// Views
import ErrorModal from "../../shared/ErrorModal";

function LoginForm({ t }) {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userFacingError, setUserFacingError] = useState({
    title: "",
    message: "",
    show: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (event.currentTarget.checkValidity() === false) {
      return;
    } else {
      logInWithEmailAndPassword(email, password, setUserFacingError);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <h5>{t("Email")}</h5>
        <InputGroup as={Col}>
          <InputGroup.Text>{t("Email")}</InputGroup.Text>
          <FormControl
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email_login"
            minLength={5}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <h5>{t("Password")}</h5>
        <InputGroup as={Col}>
          <InputGroup.Text>{t("Password")}</InputGroup.Text>
          <FormControl
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password_login"
            minLength={5}
          />
        </InputGroup>
      </Row>
      <Row>
        <Col>
          <p style={{ color: "gray" }}>
            {t("Forgot your Password?")}{" "}
            <Link to={"/account/reset-password"}>{t("Reset Password")}</Link>.
          </p>
        </Col>
      </Row>
      <Row>
        <InputGroup as={Col}>
          <Button variant="primary" type="submit">
            {t("Login")}
          </Button>
        </InputGroup>
      </Row>
      <ErrorModal
        userFacingError={userFacingError}
        setUserFacingError={setUserFacingError}
      />
    </Form>
  );
}

export default withTranslation()(LoginForm);
