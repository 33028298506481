import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithRedirect,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { BookingStatus, getDaysArray } from "../other/Utilities";

import app from "./FirebaseConfig";

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Firestore collections
const usersRef = collection(db, "users");
const homesRef = collection(db, "homes");
const productsRef = collection(db, "products");
const bookingsRef = collection(db, "bookings");

// Storage refs
const homeImagesRef = ref(storage, "homeImages");
const productImagesRef = ref(storage, "productImages");

////////////////////
// Authentication //
////////////////////

const registerWithEmailAndPassword = async (
  email,
  password,
  userData,
  setUserFacingError
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(usersRef, user.uid), userData);
    sendEmailVerification(user);
  } catch (err) {
    setUserFacingError({
      title: "Error",
      message: err.message,
      show: true,
    });
  }
};

const registerWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithRedirect(auth, provider);
};

const registerWithApple = async () => {
  const provider = new OAuthProvider("apple.com");
  await signInWithRedirect(auth, provider);
  // TODO: Finish registration with Apple
};

const logInWithEmailAndPassword = async (
  email,
  password,
  setUserFacingError
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    setUserFacingError({
      title: "Error",
      message: err.message,
      show: true,
    });
  }
};

const sendPasswordReset = async (email, setSent, setUserFacingError) => {
  try {
    await sendPasswordResetEmail(auth, email);
    setSent(true);
  } catch (err) {
    console.log(err.message);
    setUserFacingError({
      title: "Error",
      message: err.message,
      show: true,
    });
  }
};

const logout = () => {
  signOut(auth);
};

//////////
// User //
//////////

const getUserData = async (uid, setData) => {
  const docRef = doc(usersRef, uid);
  const docSnap = await getDoc(docRef);
  var data = docSnap.data();
  if (data !== undefined) data.uid = docSnap.id;
  setData(data);
};

const updateUserData = async (uid, data) => {
  const docRef = doc(usersRef, uid);
  data.lastUpdate = new Date();
  await updateDoc(docRef, data, { merge: true });
};

const createStripeConnectedAccount = httpsCallable(
  functions,
  "createStripeConnectedAccount"
);

//////////
// Home //
//////////

const createHome = async (home) => {
  const docRef = await addDoc(homesRef, home);
  return docRef.id;
};

const getHome = async (homeId, setData) => {
  const docRef = doc(homesRef, homeId);
  const docSnap = await getDoc(docRef);
  var data = docSnap.data();
  if (data !== undefined) data.id = docSnap.id;
  setData(data);
};

const observeHome = async (homeId, setData, setUnsub) => {
  // NOTE: Does not work yet! -> Unclear why.
  const docRef = doc(homesRef, homeId);
  const unsub = onSnapshot(docRef, (doc) => {
    var data = doc.data();
    if (data !== undefined) data.id = doc.id;
    setData(data);
  });
  setUnsub(unsub);
};

const updateHome = async (homeId, data) => {
  const docRef = doc(homesRef, homeId);
  data.lastUpdate = new Date();
  await updateDoc(docRef, data, { merge: true });
};

const getOwnHomes = async (uid, setData, setLoading) => {
  const q = query(
    homesRef,
    where("ownerId", "==", uid),
    orderBy("createdDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    setData((prev) => [...prev, data]);
  });
  setLoading(false);
};

const searchHomes = async (
  searchParams,
  setHomes,
  setParentHomes,
  setLoading
) => {
  setLoading(true);
  var q = query(
    homesRef,
    where("reviewStatus", "==", 2),
    where("activeState", "==", true),
    orderBy("createdDate", "desc")
  );
  const sortParams = {
    amenities: [],
    specialInfos: [],
    homeType: [],
  };
  const allowedSearchKeys = Object.keys(sortParams);
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (allowedSearchKeys.includes(param)) sortParams[param].push(value);
  }

  const querySnapshot = await getDocs(q);
  var tempList = [];
  querySnapshot.forEach((doc) => {
    var home = doc.data();
    home.id = doc.id;
    // Apply additional filters which could not be used in the query (due to Firestore restrictions)
    var addHome = true;
    if (
      sortParams.specialInfos.length > 0 &&
      !sortParams.specialInfos.every((ai) => home.specialInfos.includes(ai))
    ) {
      addHome = false;
    }
    if (
      sortParams.amenities.length > 0 &&
      !sortParams.amenities.every((ai) => home.amenities.includes(ai))
    ) {
      addHome = false;
    }
    if (
      sortParams.homeType.length > 0 &&
      !sortParams.homeType.includes(home.homeType)
    ) {
      addHome = false;
    }
    if (addHome) tempList.push(home);
    //}
  });
  setHomes(tempList);
  setParentHomes(tempList);
  setLoading(false);
};

const deleteHome = async (homeId) => {
  const docRef = doc(homesRef, homeId);
  await deleteDoc(docRef);
  // NOTE: The Images are not deleted, because they are saved for the bookings
};

const homeIsAvailable = async (booking) => {
  // Check if the home is still available for the given booking dates
  const q = query(
    bookingsRef,
    where("home.id", "==", booking.home.id),
    where("status", "==", BookingStatus.succeeded)
  );
  var homeBookings = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    homeBookings.push(data);
  });
  var homeBookingDates = [];
  for (let i = 0; i < homeBookings.length; i++) {
    var startDate = homeBookings[i].startDate.toDate();
    var endDate = homeBookings[i].endDate.toDate();
    homeBookingDates = homeBookingDates.concat(
      getDaysArray(startDate, endDate)
    );
  }
  function isInArray(array, value) {
    return !!array.find((item) => {
      return item.getTime() === value.getTime();
    });
  }
  return (
    !isInArray(homeBookingDates, booking.startDate.toDate()) &&
    !isInArray(homeBookingDates, booking.endDate.toDate())
  );
};

//////////////
// Products //
//////////////

const createProduct = async (product) => {
  const docRef = await addDoc(productsRef, product);
  return docRef.id;
};

const updateProduct = async (productId, data) => {
  const docRef = doc(productsRef, productId);
  data.lastUpdate = new Date();
  await updateDoc(docRef, data, { merge: true });
};

const getProduct = async (productId, setData) => {
  const docRef = doc(productsRef, productId);
  const docSnap = await getDoc(docRef);
  var data = docSnap.data();
  if (data !== undefined) data.id = docSnap.id;
  setData(data);
};

const getHomeProducts = async (homeId, setData, setLoading) => {
  setLoading(true);
  const q = query(
    productsRef,
    where("homeId", "==", homeId),
    orderBy("createdDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    setData((prev) => [...prev, data]);
  });
  setLoading(false);
};

const deleteProduct = async (productId) => {
  const docRef = doc(productsRef, productId);
  await deleteDoc(docRef);
  // NOTE: The Images are not deleted
};

/////////////
// Booking //
/////////////

const createBooking = async (booking) => {
  const docRef = await addDoc(bookingsRef, booking);
  return docRef.id;
};

const updateBooking = async (bookingId, data) => {
  const docRef = doc(bookingsRef, bookingId);
  await updateDoc(docRef, data, { merge: true });
};

const listenToBooking = async (bookingId, setData) => {
  const unsubscribe = onSnapshot(doc(bookingsRef, bookingId), (doc) => {
    var data = doc.data();
    if (data !== undefined) data.id = doc.id;
    setData(data);
  });
  return unsubscribe;
};

const getBooking = async (bookingId, setData) => {
  const docRef = doc(bookingsRef, bookingId);
  const docSnap = await getDoc(docRef);
  var data = docSnap.data();
  if (data !== undefined) data.id = docSnap.id;
  setData(data);
};

const getOwnBookings = async (uid, setData, setLoading) => {
  const q = query(
    bookingsRef,
    where("camper.userId", "==", uid),
    where("status", "==", BookingStatus.succeeded),
    orderBy("bookingDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    setData((prev) => [...prev, data]);
  });
  setLoading(false);
};

const getReceivedBookings = async (uid, setData, setLoading) => {
  const q = query(
    bookingsRef,
    where("home.ownerId", "==", uid),
    where("status", "==", BookingStatus.succeeded),
    orderBy("bookingDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    setData((prev) => [...prev, data]);
  });
  setLoading(false);
};

const getBookingsForHome = async (homeId, setData, setLoading) => {
  const q = query(
    bookingsRef,
    where("home.id", "==", homeId),
    where("status", "==", BookingStatus.succeeded),
    orderBy("bookingDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    data.id = doc.id;
    setData((prev) => [...prev, data]);
  });
  setLoading(false);
};

////////////
// Images //
////////////

function uploadImageAsPromise(
  imageRef,
  image,
  index,
  imageListLength,
  setProgress,
  collectionName
) {
  /** @type {any} */
  const metadata = {
    contentType: "image/jpeg",
    "Cache-Control": "public,max-age=3600",
    collectionName: collectionName,
  };
  // Upload Task
  const uploadTask = uploadBytesResumable(imageRef, image, metadata);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (index / imageListLength) * 100 +
          (snapshot.bytesTransferred / snapshot.totalBytes) *
            100 *
            (1 / imageListLength)
      );
      setProgress(progress);
    },
    (error) => {
      console.log(error);
    },
    () => {}
  );
  return uploadTask;
}

async function uploadHomeImages(homeId, images, setProgress) {
  const docRef = doc(homesRef, homeId);

  // Delete previous images in case for updating
  await deleteAllHomeImages(homeId, images.length);

  // Upload images one by one
  var imageRefs = [];
  for (let index = 0; index < images.length; index++) {
    const imageRef = ref(
      homeImagesRef,
      docRef.id + "/" + docRef.id + "_" + index + ".jpg"
    );
    imageRefs.push(imageRef);
    await uploadImageAsPromise(
      imageRef,
      images[index],
      index,
      images.length,
      setProgress,
      "homes"
    );
  }

  // NOTE: The following functionality is also implemented in the firebase function:generateThumbnail, but is not used due to display bug on HomeDashboard (the home images are not loaded since the urls are added later when using the functions)
  // Get the downloadURLs for the images
  var downloadURLs = [];
  for (let index = 0; index < imageRefs.length; index++) {
    const downloadURL = await getDownloadURL(imageRefs[index]);
    downloadURLs.push(downloadURL);
  }
  // Update the imageURLs in the homeRef
  await updateDoc(docRef, {
    imageDates: Array(downloadURLs.length).fill(new Date()),
    imageLinks: downloadURLs,
  });
}

async function uploadProductImages(productId, images, setProgress) {
  const docRef = doc(productsRef, productId);

  // Delete previous images in case for updating
  await deleteAllProductImages(productId, images.length);

  // Upload images one by one
  var imageRefs = [];
  for (let index = 0; index < images.length; index++) {
    const imageRef = ref(
      productImagesRef,
      docRef.id + "/" + docRef.id + "_" + index + ".jpg"
    );
    imageRefs.push(imageRef);
    await uploadImageAsPromise(
      imageRef,
      images[index],
      index,
      images.length,
      setProgress,
      "products"
    );
  }

  // NOTE: The following functionality is also implemented in the firebase function:generateThumbnail, but is not used due to display bug on HomeDashboard (the home images are not loaded since the urls are added later when using the functions)
  // Get the downloadURLs for the images
  var downloadURLs = [];
  for (let index = 0; index < imageRefs.length; index++) {
    const downloadURL = await getDownloadURL(imageRefs[index]);
    downloadURLs.push(downloadURL);
  }
  // Update the imageURLs in the homeRef
  await updateDoc(docRef, {
    imageDates: Array(downloadURLs.length).fill(new Date()),
    imageLinks: downloadURLs,
  });
}

async function deleteAllHomeImages(homeId, nrOfImages) {
  for (let i = 0; i < nrOfImages; i++) {
    const imgRef = ref(homeImagesRef, homeId + "/" + homeId + "_" + i + ".jpg");
    // Delete the file
    deleteObject(imgRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }
}

async function deleteAllProductImages(productId, nrOfImages) {
  for (let i = 0; i < nrOfImages; i++) {
    const imgRef = ref(
      productImagesRef,
      productId + "/" + productId + "_" + i + ".jpg"
    );
    // Delete the file
    deleteObject(imgRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }
}

////////////
// Export //
////////////

export default app;

export {
  // General
  auth,
  db,
  storage,
  functions,
  analytics,
  // Auth
  registerWithEmailAndPassword,
  registerWithApple,
  registerWithGoogle,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
  // User
  getUserData,
  updateUserData,
  createStripeConnectedAccount,
  // Home
  createHome,
  getHome,
  observeHome,
  updateHome,
  deleteHome,
  homeIsAvailable,
  getOwnHomes,
  searchHomes,
  // Product
  createProduct,
  updateProduct,
  getProduct,
  getHomeProducts,
  deleteProduct,
  // Booking
  createBooking,
  updateBooking,
  getBooking,
  listenToBooking,
  getOwnBookings,
  getReceivedBookings,
  getBookingsForHome,
  // Images
  uploadHomeImages,
  uploadProductImages,
};
