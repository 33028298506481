import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

import { googleAPIKey } from "../../other/Keys";

const render = (status) => {
  return <h1>{status}</h1>;
};

const MapComponent = ({
  onClick,
  onIdle,
  children,
  onPan,
  pinToCurrentLocation,
  style,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  const google = window.google;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new google.maps.Map(ref.current, {
          fullscreenControl: false,
          streetViewControl: false,
        })
      );
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    // Pin to Current Location with Button Click
    if (pinToCurrentLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.panTo(pos);
          onPan(pos);
        },
        () => {}
      );
    }
  }, [pinToCurrentLocation]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();
  const google = window.google;

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (marker) {
      marker.addListener("dragend", (e) => options.onDrag(e));
    }
  }, [marker]);

  return null;
};

const Polygon = (options) => {
  const [polygon, setPolygon] = useState();
  const google = window.google;

  useEffect(() => {
    if (!polygon) {
      setPolygon(new google.maps.Polygon());
    }

    // remove marker from map on unmount
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon]);

  useEffect(() => {
    if (polygon) {
      polygon.setOptions(options);
    }
  }, [polygon, options]);

  function polygonPathToJSON(polygon) {
    let temp = [];
    polygon.getPath().forEach(function (xy, i) {
      temp.push({ lat: xy.lat(), lng: xy.lng() });
    });
    return temp;
  }

  function getPolygonArea(polygon) {
    return google.maps.geometry.spherical.computeArea(polygon.getPath());
  }

  function getDistanceMarkerPolygon(marker, polygon) {
    // Returns the distance, in meters, between two LatLngs.
    return google.maps.geometry.spherical.computeDistanceBetween(
      marker,
      polygonPathToJSON(polygon)[0]
    );
  }

  useEffect(() => {
    if (polygon) {
      polygon.addListener("dragend", () =>
        options.onDrag(
          polygonPathToJSON(polygon),
          getPolygonArea(polygon),
          getDistanceMarkerPolygon(options.marker, polygon)
        )
      );
      polygon.addListener("mouseup", () =>
        options.onMouseup(
          polygonPathToJSON(polygon),
          getPolygonArea(polygon),
          getDistanceMarkerPolygon(options.marker, polygon)
        )
      );
    }
  }, [polygon]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  const google = window.google;
  if (
    isLatLngLiteral(a) ||
    a instanceof google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof google.maps.LatLng
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function MapEdit({
  marker,
  setMarker,
  polygon,
  setPolygon,
  setPolygonArea,
  setDistanceMarkerPolygon,
  pinToCurrentLocation,
  pinToPolygon,
  pinToMarker,
}) {
  const [zoom, setZoom] = useState(6); // initial zoom
  const [center, setCenter] = useState({
    lat: 48.14,
    lng: 14.03,
  }); // initial center
  const [mapTypeId, setMapTypeId] = useState("roadmap");

  const onClickMap = (e) => {
    // avoid directly mutating state
    setMarker(e.latLng.toJSON());
  };

  const onPanMap = (latLng) => {
    // avoid directly mutating state
    setMarker(latLng);
  };

  const onIdleMap = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
    setMapTypeId(m.getMapTypeId());
  };

  const onDragMarker = (e) => {
    // avoid directly mutating state
    setMarker(e.latLng.toJSON());
  };

  const onDragPolygon = (polygonPath, polygonArea, distanceMarkerPolygon) => {
    // avoid directly mutating state
    setPolygon(polygonPath);
    setPolygonArea(polygonArea);
    setDistanceMarkerPolygon(distanceMarkerPolygon);
  };

  const onMouseupPolygon = (
    polygonPath,
    polygonArea,
    distanceMarkerPolygon
  ) => {
    // avoid directly mutating state
    setPolygon(polygonPath);
    setPolygonArea(polygonArea);
    setDistanceMarkerPolygon(distanceMarkerPolygon);
  };

  useEffect(() => {
    if (pinToPolygon && !polygon) {
      const defaultBounds = [
        { lat: marker.lat + 0.0004, lng: marker.lng + 0.0002 },
        { lat: marker.lat - 0.0002, lng: marker.lng - 0.0004 },
        { lat: marker.lat - 0.0004, lng: marker.lng - 0.0002 },
        { lat: marker.lat - 0.0002, lng: marker.lng + 0.0004 },
      ];
      setPolygon(defaultBounds);
      setPolygonArea(2683.8);
      setDistanceMarkerPolygon(0);
      setZoom(18);
      setCenter(marker);
      setMapTypeId("hybrid");
    }
  }, [pinToPolygon]);

  useEffect(() => {
    if (pinToMarker) {
      setCenter(marker);
    }
  }, [pinToMarker]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper apiKey={googleAPIKey} render={render}>
        <MapComponent
          center={center}
          onClick={onClickMap}
          onIdle={onIdleMap}
          zoom={zoom}
          mapTypeId={mapTypeId}
          onPan={onPanMap}
          pinToCurrentLocation={pinToCurrentLocation}
          style={{ flexGrow: "1", height: "100%" }}
          clickableIcons={false}
        >
          {marker && (
            <Marker
              key={0}
              position={marker}
              draggable={true}
              onDrag={onDragMarker}
            />
          )}
          {polygon && (
            <Polygon
              key={1}
              path={polygon}
              strokeColor={"#FF0000"}
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor={"#FF0000"}
              fillOpacity={0.35}
              editable={true}
              draggable={true}
              onDrag={onDragPolygon}
              onMouseup={onMouseupPolygon}
              marker={
                marker
              } /* Pass the marker to calculate the distance between the polygon and the marker and restrict it to a defined max. distance*/
            />
          )}
        </MapComponent>
      </Wrapper>
    </div>
  );
}

export default React.memo(MapEdit);
