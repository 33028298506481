import React from "react";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Components
import BackButton from "./BackButton";

function Heading({ t, text, backButton = false }) {
  if (backButton) {
    return (
      <Row>
        <Col md="auto" sm="auto" xs="auto">
          <BackButton />
        </Col>
        <Col>
          <h2>{t(text)}</h2>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col>
          <h2>{t(text)}</h2>
          <hr />
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(Heading);
