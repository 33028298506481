import React from "react";
import { Row, Container, Col, Image, Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import "./IndexPage.css";

// Images
import logo from "../../images/SuperbCamping_1024.png";
import lake from "../../images/lake.jpeg";
import farm from "../../images/farm.jpeg";
import mountain from "../../images/mountain.jpeg";
import Footer from "../shared/Footer";

function SuggestedPlacesCollection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const suggestions = [
    {
      title: "Lakes",
      image: lake,
      description: "Explore beautiful places next to a lake.",
      search: ["specialInfos", "lake"],
    },
    {
      title: "Farms",
      image: farm,
      description: "Discover a wide variety of regional products!",
      search: ["homeType", "farm"],
    },
    {
      title: "Mountains",
      image: mountain,
      description: "The next hike is calling!",
      search: ["specialInfos", "mountain"],
    },
  ];

  return (
    <Row xs={1} md={3}>
      {suggestions.map((suggestion) => {
        return (
          <Col key={suggestion.title}>
            <Card
              className="suggestion_card"
              onClick={() => {
                navigate(
                  `/explore?${suggestion.search[0]}=${suggestion.search[1]}`
                );
              }}
            >
              <Card.Img
                variant="top"
                src={suggestion.image}
                height="300px"
                style={{ objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{t(suggestion.title)}</Card.Title>
                <Card.Text>{t(suggestion.description)}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

function IndexPage({ t }) {
  return (
    <div id="indexContainer">
      <div className="parallax">
        <div className="parallax__layer parallax__title">
          <h1>
            <span style={{ color: "rgb(252, 50, 60)" }}>Superb</span>Camping
          </h1>
          <p>{t("Camping - anytime and anywhere.")}</p>
          <p>
            <Link to="explore">
              <Button
                variant="primary"
                style={{ backgroundColor: "#7E0131", borderColor: "#7E0131" }}
              >
                {t("Explore")}
              </Button>
            </Link>
          </p>
        </div>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
          return (
            <div key={i} className={"parallax__layer parallax__layer__" + i}>
              <img
                alt="parallax"
                className="parallax_img"
                src={require("../../images/parallax/layer_" + i + ".png")}
              />
            </div>
          );
        })}
        <div className="parallax__cover">
          <Container style={{ color: "black" }}>
            <Row style={{ minHeight: "50vh" }} xs="1" md="2" sm="2">
              <Col>
                <h3>{t("Camping - anytime and anywhere.")}</h3>
                <p>
                  <i>
                    {t(
                      "Find private places for camping, caravaning and parking."
                    )}
                  </i>
                </p>
                <p>
                  {t(
                    "Easy and instant booking to spend a night during your trips or to rent your own property."
                  )}
                </p>
                <p>
                  <Link to="explore">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#7E0131",
                        borderColor: "#7E0131",
                      }}
                    >
                      {t("Explore")}
                    </Button>
                  </Link>
                </p>
              </Col>
              <Col>
                <p style={{ textAlign: "center" }}>
                  <Image src={logo} style={{ width: 250, borderRadius: 30 }} />
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 style={{ marginBottom: 20 }}>{t("Suggestions")}</h2>
              </Col>
            </Row>
            <SuggestedPlacesCollection />
            <Row style={{ marginTop: 40 }}>
              <Col>
                <h2>{t("Infos")}</h2>
              </Col>
            </Row>
            <Row style={{ marginBottom: 100 }} md={2} xs={1}>
              <Col>
                <h4>
                  <i className="bi-bag-fill"></i> {t("For Travelers")}
                </h4>
                <p style={{ whiteSpace: "pre-line" }}>
                  <Trans i18nKey="travelers.index.text" />
                </p>
              </Col>
              <Col>
                <h4>
                  <i className="bi-house-fill"></i> {t("For Hosts")}
                </h4>
                <p style={{ whiteSpace: "pre-line" }}>
                  <Trans i18nKey="hosts.index.text" />
                </p>
              </Col>
            </Row>
          </Container>
          <footer>
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(IndexPage);
