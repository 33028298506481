import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

function ErrorModal({ t, userFacingError, setUserFacingError }) {
  const handleClose = () =>
    setUserFacingError({
      title: userFacingError.title,
      message: userFacingError.message,
      show: !userFacingError.show,
    });

  return (
    <Modal show={userFacingError.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t(userFacingError.title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t(userFacingError.message)}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withTranslation()(ErrorModal);
