import React from "react";
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Image,
} from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Images
import appLogo from "../../images/SuperbCamping_1024.png";

// Services
import { auth, logout } from "../../services/Firebase";

function NavigationBar({ t }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  function LoginDropdownMenu() {
    return (
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/account/login")}>
          {t("Login")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/account/register")}>
          {t("Register")}
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  }

  function AccountDropdownMenu() {
    return (
      <Dropdown.Menu>
        <Dropdown.Item disabled={true}>{user && user.email}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => navigate("/account")}>
          {t("Account")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/account/travels")}>
          {t("Travels")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/account/create-home")}>
          {t("Create Accommodation")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <Button
            variant="outline-danger"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            {t("Logout")}
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  }

  return (
    <Navbar bg="light" fixed="top">
      <Container fluid>
        <Navbar.Brand
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <Image
            src={appLogo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            rounded="true"
          />{" "}
          {t("appName")}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: 100 }}
            navbarScroll
          >
            <Nav.Link onClick={() => navigate("/explore")}>
              {t("Explore")}
            </Nav.Link>
          </Nav>
          <Dropdown align="end" style={{ marginRight: 5 }}>
            <Dropdown.Toggle
              variant="success"
              style={{
                border: "1px solid gray",
                color: "black",
                backgroundColor: "var(--light-gray)",
                borderRadius: 50,
                padding: "4px 9px 4px 9px",
              }}
            >
              <i
                className="bi-person-circle"
                style={{ fontSize: 23, color: user ? "blue" : "black" }}
              ></i>
            </Dropdown.Toggle>
            {user ? <AccountDropdownMenu /> : <LoginDropdownMenu />}
          </Dropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withTranslation()(NavigationBar);
