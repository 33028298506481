import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

// Components
import ErrorModal from "../../shared/ErrorModal";
import Heading from "../../shared/Heading";

// Services
import { sendPasswordReset } from "../../../services/Firebase";

function SentView() {
  const { t } = useTranslation();
  return (
    <>
      <h5>{t("Email sent!")}</h5>
      <p>{t("Check your emails to reset the password.")}</p>
      <p>
        <Link to={"/account/login"}>
          <Button variant="outline-primary">{t("Login")}</Button>
        </Link>
      </p>
    </>
  );
}

function ResetPasswordForm({ setSent }) {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [userFacingError, setUserFacingError] = useState({
    title: "",
    message: "",
    show: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (event.currentTarget.checkValidity() === false) {
      return;
    } else {
      sendPasswordReset(email, setSent, setUserFacingError);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <h5>{t("Email")}</h5>
        <InputGroup as={Col}>
          <InputGroup.Text>{t("Email")}</InputGroup.Text>
          <FormControl
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email_reset"
            minLength={5}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <InputGroup as={Col}>
          <Button variant="primary" type="submit">
            {t("Send password reset email")}
          </Button>
        </InputGroup>
      </Row>
      <ErrorModal
        userFacingError={userFacingError}
        setUserFacingError={setUserFacingError}
      />
    </Form>
  );
}

function ResetPassword({ t }) {
  const [sent, setSent] = useState(false);

  return (
    <Container className="mainContainer" style={{ maxWidth: 600 }}>
      <Heading text={"Reset Password"} />
      <Row>
        <Col>
          {sent ? <SentView /> : <ResetPasswordForm setSent={setSent} />}
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <p style={{ color: "gray" }}>
            {t("You don't have an account yet?")}{" "}
            <Link to={"/account/register"}>{t("Register")}</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(ResetPassword);
