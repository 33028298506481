import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

// Components
import Heading from "../../shared/Heading";
import UserForm from "./UserForm";

// Services
import { UserContext } from "../../../other/Contexts";

function EditAccount() {
  const userData = useContext(UserContext);

  return (
    <Container className="mainContainer" style={{ maxWidth: 600 }}>
      <Heading text={"Edit Account"} backButton={true} />
      <Row style={{ marginTop: -10 }}>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserForm editMode={true} userData={userData} />
        </Col>
      </Row>
    </Container>
  );
}

export default EditAccount;
