import React, { useState, useLayoutEffect } from "react";
import Geocode from "react-geocode";
import { googleAPIKey } from "./Keys";

//Icons
import { GiFarmTractor, GiForest, GiGrass } from "react-icons/gi";
import { FaParking } from "react-icons/fa";
import { FaShuttleVan, FaCar } from "react-icons/fa";
import { GiCampingTent } from "react-icons/gi";
import { FaToilet, FaShower } from "react-icons/fa";
import { GiWaterDrop, GiElectric } from "react-icons/gi";
import { IoMdBonfire } from "react-icons/io";
import { FaWater, FaShoppingBasket } from "react-icons/fa";
import {
  GiMountainCave,
  GiBigWave,
  GiRiver,
  GiKnifeFork,
} from "react-icons/gi";
import { MdBrunchDining, MdOutlineRunCircle } from "react-icons/md";
import { BiPurchaseTagAlt } from "react-icons/bi";

// General

const styles = {
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  row: {
    marginLeft: 0,
    marginRight: 0,
  },
  col: {
    paddingLeft: 0,
    paddingRight: 0,
  },
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function roundToDecimals(number, decimals = 2) {
  return (
    Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals
  );
}

function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function diffDays(startDate, endDate) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  if (startDate != null && endDate != null) {
    return Math.round(Math.abs((startDate - endDate) / oneDay));
  } else {
    return 0;
  }
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function getDaysArray(s, e) {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d));
  }
  return a;
}

const isEqual = (first, second) => {
  return JSON.stringify(first) === JSON.stringify(second);
};

const getFlagEmoji = (countryCode) =>
  String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
  );

async function translate_text(text, /*fromLang,*/ toLang, setText) {
  // Disable for now due to high Google Cloud Bill
  // let url = `https://translation.googleapis.com/language/translate/v2?key=${googleAPIKey}`;
  // url += "&q=" + encodeURI(text);
  // //url += `&source=${fromLang}`; // Can be empty, then the quota detection from Google is applied.
  // url += `&target=${toLang}`;
  // fetch(url)
  //   .then((res) => res.json())
  //   .then((res) => {
  //     let textTranslated = res.data.translations[0].translatedText; //.replace(/(&quot\;)/g,"\"");
  //     setText(textTranslated);
  //   })
  //   .catch((error) => {
  //     console.log("There was an error: ", error);
  //     setText(text);
  //   });
  // Note: There is another option how to implement this translation function (see: https://github.com/googleapis/nodejs-translate), but it is more cumbersome and uses service accounts which are not so quick to set up.
  setText(text);
}

const useLocalStorage = (storageKey, fallbackState) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey))
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

// User

function getFullUserName(userData) {
  return userData.firstName + " " + userData.lastName;
}

function isConnectedAccount(userData) {
  return (
    userData &&
    "stripeAccountId" in userData &&
    "chargesEnabled" in userData &&
    userData.chargesEnabled
  );
}

// Home

function getHomeTitle(t, home) {
  return t("homeType." + home.homeType) + ", " + home.address.city;
}

function getHomeAddressString(address) {
  return (
    address.street +
    ", " +
    address.postalCode +
    ", " +
    address.city +
    ", " +
    address.country
  );
}

function getHomeLatLngString(location, accuracy = 5) {
  // See: http://wiki.gis.com/wiki/index.php/Decimal_degrees
  // 5 decimal places = 1.11 m accuracy
  return (
    "Lat: " +
    location.latitude.toFixed(accuracy) +
    ", Long: " +
    location.longitude.toFixed(accuracy)
  );
}

function getUserFacingPrice(price, language, decimals = 2) {
  price = Number(price * 0.01).toFixed(decimals);
  if (language === "de") return price.replace(".", ",");
  else return price;
}

const getLocationFromAddress = async (street, city, postalCode, country) => {
  // Convert address to longitude/latitude
  // NOTE: In order for this to work the Geocode API must be activated in the Google Console.
  // ATTENTION: Every request costs money!!!! (4,4065 EUR / 1 Tsd), but one gets a 200 USD / Month for free
  // TODO: Configure API Restrictions for production
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(googleAPIKey);
    Geocode.setLanguage("en");
    Geocode.setRegion(country);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(street + ", " + city + ", " + postalCode).then(
      (response) => {
        resolve(response.results[0].geometry.location);
      },
      (error) => {
        //alert(error);
        reject(error);
      }
    );
  });
};

const getAddressFromLocation = async (latitude, longitude) => {
  // Convert longitude/latitude to address
  // NOTE: In order for this to work the Geocode API must be activated in the Google Console.
  // ATTENTION: Every request costs money!!!! (4,4065 EUR / 1 Tsd), but one gets a 200 USD / Month for free
  // TODO: Configure API Restrictions for production
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(googleAPIKey);
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        let street, street_number, city, postal_code, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "route":
                street = response.results[0].address_components[i].long_name;
                break;
              case "street_number":
                street_number =
                  response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].short_name;
                break;
              default:
                break;
            }
          }
        }
        resolve({
          street: street + ", " + street_number,
          city: city,
          postal_code: postal_code,
          country: country,
        });
      },
      (error) => {
        //alert(error);
        reject(error);
      }
    );
  });
};

function getHomeTypeIcon(homeType, size = 14) {
  switch (homeType) {
    case "farm":
      return <GiFarmTractor size={size} />;
    case "woods":
      return <GiForest size={size} />;
    case "garden":
      return <GiGrass size={size} />;
    case "parking":
      return <FaParking size={size} />;
    default:
      return;
  }
}

function getLimitationIcon(limitation, size = 14) {
  switch (limitation) {
    case "car":
      return <FaCar key={limitation} size={size} />;
    case "tent":
      return <GiCampingTent key={limitation} size={size} />;
    case "caravan":
      return <FaShuttleVan key={limitation} size={size} />;
    default:
      return;
  }
}

function getAmenityIcon(amenity, size = 14) {
  switch (amenity) {
    case "toilet":
      return <FaToilet size={size} />;
    case "shower":
      return <FaShower size={size} />;
    case "waterConnection":
      return <GiWaterDrop size={size} />;
    case "electricityConnection":
      return <GiElectric size={size} />;
    case "firePlace":
      return <IoMdBonfire size={size} />;
    default:
      return;
  }
}

function getSpecialInfoIcon(specialInfo, size = 14) {
  switch (specialInfo) {
    case "lake":
      return <FaWater size={size} />;
    case "mountain":
      return <GiMountainCave size={size} />;
    case "sea":
      return <GiBigWave size={size} />;
    case "river":
      return <GiRiver size={size} />;
    case "homeProducts":
      return <FaShoppingBasket size={size} />;
    default:
      return;
  }
}

// Product

function getProductTypeIcon(productType, size = 14) {
  switch (productType) {
    case "product":
      return <BiPurchaseTagAlt size={size} />;
    case "food":
      return <GiKnifeFork size={size} />;
    case "activity":
      return <MdOutlineRunCircle size={size} />;
    case "package":
      return <MdBrunchDining size={size} />;
    default:
      return;
  }
}

// Booking

const BookingStatus = {
  amount_capturable_updated: "amount_capturable_updated",
  canceled: "canceled",
  created: "created",
  new: "new", // Not part of the original stripe payment_intent events
  partially_funded: "partially_funded",
  payment_failed: "payment_failed",
  processing: "processing",
  requires_action: "requires_action",
  succeeded: "succeeded",
};

// BookingStatus

export default null;
export {
  // General
  styles,
  useWindowSize,
  roundToDecimals,
  numberWithCommas,
  diffDays,
  getAge,
  getDaysArray,
  isEqual,
  getFlagEmoji,
  translate_text,
  useLocalStorage,
  // User
  getFullUserName,
  isConnectedAccount,
  // Home
  getHomeTitle,
  getHomeAddressString,
  getHomeLatLngString,
  getUserFacingPrice,
  getLocationFromAddress,
  getAddressFromLocation,
  getHomeTypeIcon,
  getLimitationIcon,
  getAmenityIcon,
  getSpecialInfoIcon,
  // Product
  getProductTypeIcon,
  // Booking
  BookingStatus,
};
