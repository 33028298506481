import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Card, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import countries from "i18n-iso-countries";
import "./AccountView.css";

// Components
import BookingsChart from "./BookingsChart";
import Heading from "../../shared/Heading";

// Services
import {
  auth,
  logout,
  getOwnHomes,
  getReceivedBookings,
} from "../../../services/Firebase";
import {
  styles,
  getFullUserName,
  isConnectedAccount,
  getHomeTitle,
  getHomeAddressString,
  getFlagEmoji,
  getAge,
} from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function AccountView({ t, i18n }) {
  const [user] = useAuthState(auth);
  const userData = useContext(UserContext);
  const [ownHomes, setOwnHomes] = useState([]);
  const [ownHomesLoading, setOwnHomesLoading] = useState(true);
  const [receivedBookings, setReceivedBookings] = useState([]);
  const [receivedBookingsLoading, setReceivedBookingsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    userData && getOwnHomes(userData.uid, setOwnHomes, setOwnHomesLoading);
  }, [userData]);

  useEffect(() => {
    userData &&
      getReceivedBookings(
        userData.uid,
        setReceivedBookings,
        setReceivedBookingsLoading
      );
  }, [userData]);

  useEffect(() => {
    countries.registerLocale(
      require("i18n-iso-countries/langs/" + i18n.resolvedLanguage + ".json")
    );
  }, [i18n.language]);

  function UserDataRow() {
    return (
      <Row>
        <Col>
          <p>
            {t("Name")}: {userData && getFullUserName(userData)}{" "}
            <span style={{ float: "right" }}>
              <Link className="plainLink" to={"/account/edit"}>
                <i className="bi-pencil"></i> {t("Edit")}
              </Link>
            </span>
          </p>
          <p>
            {t("Email")}: {userData && userData.email}{" "}
            <span style={{ marginLeft: 20, color: "red" }}>
              {!user.emailVerified && t("Not verified!")}
            </span>
          </p>
          <p>
            {t("Country")}:{" "}
            {userData && userData.country !== undefined ? (
              getFlagEmoji(userData.country) +
              " " +
              countries.getName(userData.country, i18n.resolvedLanguage)
            ) : (
              <span style={{ color: "red" }}>{t("?")}</span>
            )}
          </p>
          <p>
            {t("Birthdate")}:{" "}
            {userData && userData.birthDate !== undefined ? (
              userData.birthDate
                .toDate()
                .toLocaleDateString(i18n.resolvedLanguage)
            ) : (
              <span style={{ color: "red" }}>{t("?")}</span>
            )}
          </p>
          <hr />
        </Col>
      </Row>
    );
  }

  function ConnectToStripeRow() {
    return (
      <Row>
        <Col>
          <h5>
            {isConnectedAccount(userData)
              ? t("Host management")
              : t("Become a host")}
          </h5>
          <p>
            {isConnectedAccount(userData)
              ? t(
                  "You account is connected with Stripe. Login to see all details."
                )
              : t(
                  "Do you want to rent out your own places? Then register here for a connected account. The onboarding process just takes a few steps."
                )}
          </p>
          <p>
            <Link
              to={
                userData &&
                (userData.birthDate === undefined ||
                  getAge(userData.birthDate.toDate()) < 18)
                  ? "/account"
                  : "/account/connect-to-stripe"
              }
              target="_blank"
            >
              <Button variant="outline-success">
                {isConnectedAccount(userData)
                  ? t("Login to Stripe")
                  : t("Connect with Stripe")}
              </Button>
            </Link>
          </p>
          {userData &&
          (userData.birthDate === undefined ||
            getAge(userData.birthDate.toDate()) < 18) ? (
            <p style={{ color: "red" }}>
              {t("You must be at least 18 years of age to become a host!")}
            </p>
          ) : null}
          <hr />
        </Col>
      </Row>
    );
  }

  function OwnHomesRow() {
    return (
      <Row>
        <Col>
          <h5>{t("Own Accommodations")}</h5>
          {ownHomesLoading ? (
            <Spinner animation="border" size="sm" style={{ margin: 10 }} />
          ) : ownHomes.length < 1 ? (
            <p>
              {t(
                "Do you want to rent out your own places? Then create your first place for rent here."
              )}
            </p>
          ) : (
            <>
              <p style={{ color: "gray" }}>
                {t("Total") + ": " + ownHomes.length}
              </p>
              <HomeCardList />
            </>
          )}
          <p>
            <Link to={"/account/create-home"}>
              <Button variant="outline-primary">
                <i className="bi-plus-lg"></i> {t("Create Accommodation")}
              </Button>
            </Link>
          </p>
          <hr />
        </Col>
      </Row>
    );
  }

  function HomeCardList() {
    return (
      <Container fluid style={styles.container}>
        <Row className="x-scroller" style={styles.row}>
          <Col style={styles.col}>
            {ownHomes.map((home) => {
              return (
                <Card
                  key={home.id}
                  style={{
                    display: "inline-block",
                    marginBottom: 20,
                    width: 250,
                    minWidth: 250,
                    maxWidth: 250,
                    marginRight: 20,
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={home.thumbnailLinks.sort()[0]}
                    height="150px"
                    style={{ objectFit: "cover" }}
                  />
                  <Card.Body>
                    <Card.Title className="homeCardText">
                      {getHomeTitle(t, home)}
                    </Card.Title>
                    <Card.Text
                      className="homeCardText"
                      style={{ color: "gray" }}
                    >
                      <i className="bi-geo-fill"></i>{" "}
                      {getHomeAddressString(home.address)}
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(`/account/home-dashboard/` + home.id);
                      }}
                    >
                      <i className="bi-pencil"></i> {t("Edit")}
                    </Button>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }

  function OwnBookingsRow() {
    return (
      <Row>
        <Col>
          <h5>{t("Rentals Summary")}</h5>
          <p>
            {t(
              "Overview of all received bookings for all your accommodations."
            )}
          </p>
          {receivedBookingsLoading ? (
            <Spinner animation="border" size="sm" style={{ margin: 10 }} />
          ) : receivedBookings.length < 1 ? (
            <p>{t("No received bookings yet.")}</p>
          ) : (
            <>
              <p style={{ color: "gray" }}>
                {t("Total") + ": " + receivedBookings.length}
              </p>
              <div style={{ height: 400 }}>
                <BookingsChart
                  bookings={receivedBookings}
                  userData={userData}
                  ownHomes={ownHomes.slice(0, 24)} // Limit the number of houses on the chart temporarily (TODO: Find better solution)
                />
              </div>
            </>
          )}
          <hr />
        </Col>
      </Row>
    );
  }

  function LogoutButtonRow() {
    return (
      <Row>
        <Col>
          <p>
            <Button
              variant="outline-danger"
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              {t("Logout")}
            </Button>
          </p>
        </Col>
      </Row>
    );
  }

  return (
    <Container className="mainContainer">
      <Heading text={"Account"} />
      <UserDataRow />
      <ConnectToStripeRow />
      <OwnHomesRow />
      <OwnBookingsRow />
      <LogoutButtonRow />
    </Container>
  );
}

export default withTranslation()(AccountView);
