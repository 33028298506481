import React from "react";
import { Container } from "react-bootstrap";

// Components
import Heading from "./Heading";

function NotFound() {
  return (
    <Container className="mainContainer">
      <Heading text={"Not Found"} />
    </Container>
  );
}

export default NotFound;
