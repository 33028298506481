import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";

function FAQs({ t }) {
  // TODO: Translate to English

  const questions_travelers = [
    {
      q: "Welche Regeln muss ich beachten wenn ich an meinem gebuchten Stellplatz ankomme?",
      a: "Verhalte dich respekvoll und beachte etwaige Anmerkungen der Grundstücksbesitzer in der Beschreibung des Stellplatzes.",
    },
    {
      q: "Was muss ich beachten wenn ich den Stellplatz wieder verlasse?",
      a: "Hinterlasse den Stellplatz so wie du ihn aufgefunden hast! Etwaiger Müll muss entsorgt werden.",
    },
    {
      q: "Kann ich meine Buchung stornieren?",
      a: "Stornieren von Buchungen ist derzeit noch nicht möglich.",
    },
  ];

  const questions_hosts = [
    {
      q: "Darf ich das eigene Grundstück zum Kampieren vermieten?",
      a: "Das kommt ganz auf die Gesetze im jeweiligen Bundesland an. Informiere dich zuerst genau bevor du dein Grunstück zum Vermieten anbietest.",
    },
    {
      q: "Wie erhalte ich meine Mieteinnahmen?",
      a: "Die Einnahmen werden regelmäßig auf das angegebene Konto überwiesen.",
    },
  ];

  function QuestionList({ source }) {
    return source.map((el) => {
      return (
        <Row>
          <Col>
            <h5>{t(el.q)}</h5>
            <p>{t(el.a)}</p>
          </Col>
        </Row>
      );
    });
  }

  return (
    <Container className="mainContainer">
      <Heading text={"FAQs - Frequently Asked Questions"} />
      <Row>
        <Col>
          <h3>
            <i className="bi-bag-fill"></i> {t("For Travelers")}
          </h3>
        </Col>
      </Row>
      <QuestionList source={questions_travelers} />
      <Row style={{ marginTop: 30 }}>
        <Col>
          <h3>
            <i className="bi-house-fill"></i> {t("For Hosts")}
          </h3>
        </Col>
      </Row>
      <QuestionList source={questions_hosts} />
    </Container>
  );
}

export default withTranslation()(FAQs);
