// General
const domainMain = "https://superbcamping.com";
const stripePayoutURL = "https://dashboard.stripe.com/settings/payouts";
const countryList = ["AT", "DE", "CH"];
const currencyList = ["EUR", "CHF"];
const socialMediaList = [
  [
    "facebook",
    "https://www.facebook.com/SuperbCamping-105762144556348",
    "bi-facebook",
  ],
  ["instagram", "https://www.instagram.com/superbcamping", "bi-instagram"],
  ["twitter", "https://twitter.com/SuperbCamping", "bi-twitter"],
];
// Home
const homeTypes = ["farm", "woods", "garden", "parking"];
const specialInfos = ["lake", "mountain", "sea", "river", "homeProducts"];
const limitations = ["caravan", "tent", "car"];
const amenities = [
  ["toilet", 1],
  ["shower", 1],
  ["waterConnection", 1],
  ["electricityConnection", 1],
  ["firePlace", 0],
];
const defaultHomePrice = 1000; // = 10,00
// Product
const productTypes = ["product", "food", "activity", "package"];

export {
  // General
  domainMain,
  stripePayoutURL,
  countryList,
  currencyList,
  socialMediaList,
  // Home
  homeTypes,
  specialInfos,
  limitations,
  amenities,
  defaultHomePrice,
  // Product
  productTypes,
};
