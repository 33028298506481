import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  ProgressBar,
  Spinner,
  Image,
  Modal,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import "./ProductForm.css";

// Components
import DragAndDrop from "../../shared/DragAndDrop";

// Services
import { productTypes } from "../../../other/Constants";
import { getProductTypeIcon } from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function ProductForm({
  t,
  editMode,
  progress,
  submittingInProgress,
  setSubmittingInProgress,
  product,
  images,
  setImages,
  sendForm,
}) {
  const [validated, setValidated] = useState(false);

  // ProductForm Fields
  const [name, setName] = useState("");
  const [productTypeCheckedState, setProductTypeCheckedState] = useState(0);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [publishNow, setPublishNow] = useState(true);

  // Error Message
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const handleCloseErrorMsg = () => setShowErrorMsg(false);
  const handleShowErrorMsg = () => setShowErrorMsg(true);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(false);

  // User
  const userData = useContext(UserContext);

  let [searchParams] = useSearchParams();

  function getHomeId() {
    let params = searchParams.getAll("homeId");
    if (params.length === 1) return params[0];
    else return null;
  }

  useEffect(() => {
    if (description.length > 50) setDescriptionErrorMsg(false);
  }, [description]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (
      event.currentTarget.checkValidity() === false ||
      (images.length === 0 && !product) ||
      images.length > 10 ||
      getHomeId() === null
    ) {
      handleShowErrorMsg();
      return;
    } else {
      setSubmittingInProgress(true);
      const productNew = {
        activeState: publishNow,
        name: name,
        productType: productTypes[productTypeCheckedState],
        lastUpdate: new Date(),
        createdDate: new Date(),
        ownerId: userData.uid,
        homeId: getHomeId(),
        description: description,
        price: price,
        reviewStatus: 0, // 0: pending for review, 1: in review, 2: review success, 3: review ended in failure
      };
      if (editMode) {
        productNew.imageLinks = product.imageLinks;
        productNew.thumbnailLinks = product.thumbnailLinks;
        productNew.imageDates = product.imageDates;
      } else {
        productNew.imageLinks = [];
        productNew.thumbnailLinks = [];
        productNew.imageDates = [];
      }
      sendForm(productNew, images);
    }
  };

  function populateForm() {
    // Populate the Form with Product (for edit mode)
    setName(product.name);
    var productTypesTemp = new Array(productTypes.length).fill(false);
    productTypesTemp[productTypes.indexOf(product.productType)] = true;
    setProductTypeCheckedState(productTypesTemp);
    setDescription(product.description);
    setPrice(product.price);
    setPublishNow(product.activeState);
  }

  useEffect(() => {
    editMode && product && populateForm();
  }, [product]);

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  function ErrorMessage() {
    return (
      <Modal show={showErrorMsg} onHide={handleCloseErrorMsg}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Error")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "Make sure to fill out all the required fields, including the images."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseErrorMsg}>
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <h5>{t("Name")}</h5>
        <InputGroup as={Col} controlid="zip">
          <InputGroup.Text>{t("Name")}</InputGroup.Text>
          <FormControl
            required
            value={name}
            id="name"
            onChange={(e) => setName(e.target.value)}
            minLength={3}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <h5>{t("Type")}</h5>
        <p>{t("Select the type which best describes the product.")}</p>
        <div>
          {productTypes.map((productType, position) => (
            <Form.Check
              required
              label={
                <div className="radioButtonLabel">
                  <p>
                    {getProductTypeIcon(productType, 30)}
                    <br />
                    {t("productType." + productType)}
                  </p>
                </div>
              }
              type="radio"
              id={productType}
              name="productType"
              key={productType}
              checked={position === productTypeCheckedState}
              onChange={() => setProductTypeCheckedState(position)}
              className="radioButton"
            />
          ))}
        </div>
      </Row>
      <Row className="mb-3">
        <h5>{t("Images")}</h5>
        <p>
          {t("Select min. 1 image. The first image appears as cover image.")}
        </p>
        <DragAndDrop files={images} setFiles={setImages} maxFiles={5} />
      </Row>
      {editMode && (
        <Row>
          <Col>
            <p>
              <b>{t("Current images")}</b>
            </p>
            <p>
              {product &&
                product.thumbnailLinks.sort().map((imageLink, index) => {
                  return (
                    <Image
                      key={index}
                      src={imageLink}
                      height={100}
                      width={100}
                      style={{
                        borderRadius: 2,
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                    />
                  );
                })}
            </p>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <h5>{t("Description")}</h5>
        <p>
          {t(
            "Add a meaningful description to your new product. Price per Kg? Can it be used for cooking or grilling? What kind of activity?"
          )}
        </p>
        <InputGroup as={Col} controlid="description">
          <InputGroup.Text>{t("Description")}</InputGroup.Text>
          <FormControl
            required
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            minLength={50}
          />
        </InputGroup>
        {descriptionErrorMsg && (
          <p style={{ color: "red" }}>{t("Min. 50 Characters")}</p>
        )}
      </Row>
      <Row className="mb-3">
        <h5>{t("Price")}</h5>
        <InputGroup as={Col} controlid="price">
          <InputGroup.Text>{currency}</InputGroup.Text>
          <Form.Control
            required
            type="number"
            min="1"
            max="999"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <Col>
          <hr style={{ marginTop: 20 }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <h5>{t("Publish")}</h5>
        <p>
          {t(
            "Every product has to pass the review process before it will be published. This should not take longer than a few minutes."
          )}
        </p>
        <InputGroup as={Col} controlid="publishNow">
          <Form.Check
            label={t(
              "Publish now (this can also be changed later in the settings)"
            )}
            type="switch"
            id="publishNow"
            checked={publishNow}
            onChange={(e) => setPublishNow(e.target.value)}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <Col>
          <hr style={{ marginTop: 20 }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <InputGroup as={Col}>
          <Button
            variant="primary"
            type="submit"
            disabled={submittingInProgress}
          >
            {submittingInProgress ? (
              <Spinner animation="border" size="sm" />
            ) : product ? (
              t("Update")
            ) : (
              t("Create")
            )}
          </Button>
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <div style={{ margin: "20px 0px 0px 0px" }}>
          <ProgressBar now={progress} />
        </div>
      </Row>
      <ErrorMessage />
    </Form>
  );
}

export default withTranslation()(ProductForm);
