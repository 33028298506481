import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { withTranslation } from "react-i18next";
import GoogleButton from "react-google-button";

// Components
import LoginForm from "./LoginForm";
import Heading from "../../shared/Heading";

// Services
import { auth, registerWithGoogle } from "../../../services/Firebase";

function LoginView({ t }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect the user to the explore page when logged in.
    if (user) navigate("/explore");
  }, [user]);

  return (
    <Container className="mainContainer" style={{ maxWidth: 600 }}>
      <Heading text={"Login"} />
      <Row>
        <Col>
          <LoginForm />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <GoogleButton
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 20,
              marginBottom: 10,
            }}
            type="light"
            label={t("Sign in with Google")}
            onClick={() => registerWithGoogle()}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <p style={{ color: "gray" }}>
            {t("You don't have an account yet?")}{" "}
            <Link to={"/account/register"}>{t("Register")}</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(LoginView);
