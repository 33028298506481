import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Stack,
  Image,
  Button,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import "./TravelsListView.css";

// Components
import Heading from "../../shared/Heading";

// Services
import { getOwnBookings } from "../../../services/Firebase";
import {
  styles,
  getHomeTitle,
  getHomeAddressString,
  getUserFacingPrice,
} from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function TravelsListEntry({ booking }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  function handleClick() {
    navigate(`/account/booking/${booking.id}`);
  }

  function getHomeImage(home) {
    if (
      home.thumbnailLinks === undefined ||
      home.thumbnailLinks === null ||
      home.thumbnailLinks.length === 0
    ) {
      return (
        <p
          style={{
            width: 100,
            textAlign: "center",
            paddingTop: 40,
            backgroundColor: "lightgray",
            height: 100,
            borderRadius: 10,
          }}
        >
          <i className="bi-house-fill"></i>
        </p>
      );
    } else {
      return (
        <Image
          fluid
          src={home.thumbnailLinks.sort()[0]}
          style={{
            width: 100,
            height: 100,
            borderRadius: 10,
            objectFit: "cover",
          }}
        />
      );
    }
  }

  return (
    <Container
      fluid
      style={styles.container}
      className="travelEntry"
      onClick={handleClick}
    >
      <Row>
        <Col md="auto" sm="auto" xs="auto" style={{ marginBottom: 10 }}>
          {getHomeImage(booking.home)}
        </Col>
        <Col>
          <Stack>
            <h5>{getHomeTitle(t, booking.home)}</h5>
            <p style={{ color: "gray" }}>
              <i className="bi-geo"></i>
              {getHomeAddressString(booking.home.address)}
            </p>
            <p>
              {t("Total")}:{" "}
              <b>
                {getUserFacingPrice(booking.amount, i18n.resolvedLanguage)}{" "}
                {currency}
              </b>
            </p>
          </Stack>
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <p style={{ fontWeight: "bold" }}>{t("Check-In")}</p>
          <p>
            {booking.startDate
              .toDate()
              .toLocaleDateString(i18n.resolvedLanguage)}
          </p>
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <p style={{ fontWeight: "bold" }}>{t("Check-Out")}</p>
          <p>
            {booking.endDate.toDate().toLocaleDateString(i18n.resolvedLanguage)}
          </p>
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <p style={{ fontWeight: "bold" }}>{t("Guests")}</p>
          <p>{booking.adults + booking.children}</p>
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <p style={{ fontWeight: "bold" }}>{t("Price")}</p>
          <p>
            {getUserFacingPrice(booking.home.price, i18n.resolvedLanguage)}€ x{" "}
            {booking.nights} {t("Nights")}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function TravelsListView({ t }) {
  const userData = useContext(UserContext);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userData && getOwnBookings(userData.uid, setBookings, setLoading);
  }, [userData]);

  function displayList() {
    if (loading) {
      //return <ListGroup.Item key={"loading"}><Spinner size="sm" animation="border" /></ListGroup.Item>;
      return <></>;
    } else {
      if (bookings.length === 0) {
        return (
          <ListGroup.Item key={"empty"}>
            <h5>{t("No trips booked ... yet!")}</h5>
            <p>
              {t(
                "Time to dust off your bags and start planning your next adventure."
              )}
            </p>
            <p>
              <Link to={"/explore"}>
                <Button variant="outline-primary">{t("Explore")}</Button>
              </Link>
            </p>
          </ListGroup.Item>
        );
      } else {
        return (
          <>
            {bookings.map((booking) => {
              return (
                <ListGroup.Item key={booking.id} className="travelEntry">
                  <TravelsListEntry booking={booking} />
                </ListGroup.Item>
              );
            })}
          </>
        );
      }
    }
  }

  return (
    <Container className="mainContainer">
      <Heading text={"Travels"} />
      <Row>
        <Col>
          <ListGroup variant="flush">{displayList()}</ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(TravelsListView);
