import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Image,
  Form,
  Spinner,
  Modal,
  Card,
} from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import moment from "moment";

// Components
import MultiDatePicker from "../../shared/MultiDatePicker";
import Heading from "../../shared/Heading";

// Services
import {
  getHome,
  updateHome,
  getBookingsForHome,
  deleteHome,
  getHomeProducts,
} from "../../../services/Firebase";
import {
  getHomeTitle,
  getHomeAddressString,
  useWindowSize,
  isConnectedAccount,
  getDaysArray,
  styles,
} from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function HomeQuickView({ home }) {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col md="auto">
          <p
            style={{
              height: 120,
              width: 160,
              borderRadius: 10,
              backgroundColor: "lightgray",
              textAlign: "center",
            }}
          >
            <Image
              src={home && home.imageLinks.sort()[0]}
              style={{
                height: 120,
                borderRadius: 10,
                objectFit: "cover",
                width: "100%",
              }}
            />
          </p>
          <p>
            <Link to={`/explore/${home && home.id}`}>
              <Button variant="light" style={{ width: "100%" }}>
                <i className="bi-house"></i> {t("Show")}
              </Button>
            </Link>
          </p>
        </Col>
        <Col>
          <h5>{home && getHomeTitle(t, home)}</h5>
          <p style={{ color: "gray" }}>
            {home && getHomeAddressString(home.address)}
          </p>
          <p>{home && home.description}</p>
        </Col>
      </Row>
    </Container>
  );
}

function EditHomeSection({ home }) {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Edit details")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t("You can edit all the details of the home here.")}</p>
        </Col>
        <Col md="auto">
          <Link to={`/account/edit-home/${home && home.id}`}>
            <Button variant="primary">
              <i className="bi-pencil"></i> {t("Edit")}
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

function EditAvailability({ home }) {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const [disabledDates, setDisabledDates] = useState([]);
  const [homeBookings, setHomeBookings] = useState([]);
  const [homeBookingsLoading, setHomeBookingsLoading] = useState(true);
  const [homeBookingDates, setHomeBookingDates] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    home &&
      setDisabledDates(
        home.disabledDates.map((element) => moment(element.toDate(), "day"))
      );
  }, [home]);

  useEffect(() => {
    home &&
      getBookingsForHome(home.id, setHomeBookings, setHomeBookingsLoading);
  }, [home]);

  async function updateDisabledDates() {
    setUpdating(true);
    let tempDisabledDates = disabledDates.map((element) => element.toDate());
    await updateHome(home.id, { disabledDates: tempDisabledDates });
    setUpdating(false);
  }

  useEffect(() => {
    var tempArray = [];
    for (let i = 0; i < homeBookings.length; i++) {
      var startDate = homeBookings[i].startDate.toDate();
      var endDate = homeBookings[i].endDate.toDate();
      tempArray = tempArray.concat(getDaysArray(startDate, endDate));
    }
    setHomeBookingDates(tempArray);
  }, [homeBookings]);

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Edit availability")}</h4>
          <p>{t("Select all dates which should be disabled from booking.")}</p>
          <ul>
            <li>
              {t("Disabled Dates are displayed in")}:{" "}
              <span
                style={{
                  backgroundColor: "#ffe8bc",
                  padding: "3px 7px 3px 7px",
                  borderRadius: 3,
                }}
              >
                {t("Yellow")}
              </span>
            </li>
            <li>
              {t("Bookings are displayed in")}:{" "}
              <span
                style={{
                  backgroundColor: "lightgray",
                  padding: "3px 7px 3px 7px",
                  borderRadius: 3,
                }}
              >
                {t("Gray")}
              </span>
            </li>
          </ul>
          <p>
            <span style={{ color: "gray" }}>
              {t(
                "Note: You can only disable dates which do not already have a booking yet."
              )}
            </span>
          </p>
          <MultiDatePicker
            dates={disabledDates}
            setDates={setDisabledDates}
            numberOfMonths={width > 767 ? 2 : 1}
            blockedDates={homeBookingDates}
          />
          <Button
            style={{ marginTop: 20, float: "right" }}
            variant="primary"
            onClick={() => updateDisabledDates()}
            disabled={homeBookingsLoading || updating}
          >
            {updating ? (
              <Spinner animation="border" size="sm" variant="dark" />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

function HomeReviewStatusAlert({ home }) {
  const { t } = useTranslation();
  if (!home) return null;
  switch (home.reviewStatus) {
    case 0:
      return <Alert variant="warning">{t("Review Status: Pending")}</Alert>;
    case 1:
      return <Alert variant="warning">{t("Review Status: In Review")}</Alert>;
    case 2:
      return <Alert variant="success">{t("Review Status: Success")}</Alert>;
    case 3:
      return <Alert variant="danger">{t("Review Status: Declined")}</Alert>;
    default:
      break;
  }
}

function HomeProductsCardList({ homeProducts }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container fluid style={styles.container}>
      <Row className="x-scroller" style={styles.row}>
        <Col style={styles.col}>
          {homeProducts.map((product) => {
            return (
              <Card
                key={product.id}
                style={{
                  display: "inline-block",
                  marginBottom: 20,
                  width: 250,
                  minWidth: 250,
                  maxWidth: 250,
                  marginRight: 20,
                }}
              >
                <Card.Img
                  variant="top"
                  src={product.thumbnailLinks.sort()[0]}
                  height="150px"
                  style={{ objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="homeCardText">
                    {product.name}
                  </Card.Title>
                  <Card.Text className="homeCardText" style={{ color: "gray" }}>
                    {product.description}
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(`/account/home-dashboard/` + product.id);
                    }}
                  >
                    <i className="bi-pencil"></i> {t("Edit")}
                  </Button>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
}

function HomeProductsSection({ home }) {
  const { t } = useTranslation();
  const [homeProducts, setHomeProducts] = useState([]);
  const [homeProductsLoading, setHomeProductsLoading] = useState(true);

  useEffect(() => {
    home && getHomeProducts(home.id, setHomeProducts, setHomeProductsLoading);
  }, [home]);

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Products")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {homeProductsLoading ? (
            <Spinner animation="border" size="sm" style={{ margin: 10 }} />
          ) : homeProducts.length < 1 ? (
            <>
              <p>
                {t(
                  "If you want to offer additional products to this place you can add them here."
                )}
              </p>
            </>
          ) : (
            <>
              <p style={{ color: "gray" }}>
                {t("Total") + ": " + homeProducts.length}
              </p>
              <HomeProductsCardList homeProducts={homeProducts} />
            </>
          )}
          <Link to={`/account/create-product?homeId=${home && home.id}`}>
            <Button variant="outline-primary">
              <i className="bi-plus"></i> {t("Create Product")}
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

function PublishHomeSection({ home, userData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeState, setActiveState] = useState(false);

  const handleChange = async (event) => {
    setActiveState(!activeState);
    await updateHome(home.id, { activeState: !activeState });
  };

  useEffect(() => {
    home && setActiveState(home.activeState);
  }, [home]);

  return (
    <Container
      fluid
      style={{
        border: "1px solid lightgray",
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Publish")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t("Set the visibility of your accommodation.")}</p>
        </Col>
        <Col md="auto">
          <Form.Check
            label={t("Public")}
            type="switch"
            id="activeState"
            checked={activeState}
            onChange={(e) => handleChange(e)}
            disabled={userData && !isConnectedAccount(userData)}
          />
        </Col>
      </Row>
      {home && !isConnectedAccount(userData) && (
        <Row>
          <Col>
            <hr style={{ marginTop: -5 }} />
            <Alert variant="warning">
              {t(
                "In order to publish the accommodation and to be able to accept bookings you first need to connect your account to Stripe and enter relevant banking information for payouts."
              )}{" "}
              <br />
              <Button
                style={{ marginTop: 10 }}
                variant="outline-dark"
                onClick={() => {
                  navigate(`/account/connect-to-stripe`);
                }}
              >
                {t("Connect with Stripe")}
              </Button>
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
}

function DeleteHomeSection({ home }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Delete Home Message
  const [showDeleteHomeMsg, setShowDeleteHomeMsg] = useState(false);
  const handleCloseDeleteHomeMsg = () => setShowDeleteHomeMsg(false);
  const handleShowDeleteHomeMsg = () => setShowDeleteHomeMsg(true);

  async function onClickDeleteFinal() {
    if (home) {
      await deleteHome(home.id);
      navigate("/account");
    }
  }

  return (
    <>
      <Container
        fluid
        style={{
          border: "1px solid lightgray",
          borderRadius: 10,
          padding: 15,
          marginBottom: 20,
        }}
      >
        <Row>
          <Col>
            <h4>{t("Delete")}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t("Deleting the accommodation will be permanently!")}</p>
          </Col>
          <Col md="auto">
            <Button variant="danger" onClick={handleShowDeleteHomeMsg}>
              <i className="bi-x-square"></i> {t("Delete")}
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal show={showDeleteHomeMsg} onHide={handleCloseDeleteHomeMsg}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Deleting the accommodation will be permanently!")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteHomeMsg}>
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={onClickDeleteFinal}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function HomeDashboard({ t, i18n }) {
  let { homeId } = useParams();
  const userData = useContext(UserContext);
  const [home, setHome] = useState(null);
  //const [unsub, setUnsub] = useState(null);

  useEffect(() => {
    getHome(homeId, setHome);
    //observeHome(homeId, setHome, setUnsub);
  }, [homeId]);

  /*useEffect(() => {
    return () => {
      // Stop listening to homeObserver
      if (unsub) unsub();
    }
  }, [])*/

  return (
    <Container className="mainContainer">
      <Heading text={home && getHomeTitle(t, home)} backButton={true} />
      <Row>
        <Col>
          <Alert variant="success">
            {t("Accommodation created successfully on the %@.", {
              val:
                home &&
                home.createdDate
                  .toDate()
                  .toLocaleDateString(i18n.resolvedLanguage),
            })}
          </Alert>
          <HomeReviewStatusAlert home={home} />
          <EditHomeSection home={home} />
          <PublishHomeSection home={home} userData={userData} />
          <HomeProductsSection home={home} />
        </Col>
        <Col>
          <HomeQuickView home={home} />
          <EditAvailability home={home} />
          <DeleteHomeSection home={home} />
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(HomeDashboard);
