import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// Components
import Heading from "../../shared/Heading";
import ProductForm from "./ProductForm";

// Services
import { createProduct, uploadProductImages } from "../../../services/Firebase";

function CreateProduct() {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [submittingInProgress, setSubmittingInProgress] = useState(false);

  const sendForm = async (productNew, imagesNew) => {
    const productIdNew = await createProduct(productNew);
    await uploadProductImages(productIdNew, imagesNew, setProgress);
    navigate("/account/home-dashboard/" + productNew.homeId);
  };

  return (
    <Container
      className="mainContainer"
      style={{ marginBottom: 40, maxWidth: 800, minWidth: 350 }}
    >
      <Heading text={"Create a new product"} backButton={true} />
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <ProductForm
        editMode={false}
        progress={progress}
        submittingInProgress={submittingInProgress}
        setSubmittingInProgress={setSubmittingInProgress}
        product={null}
        images={images}
        setImages={setImages}
        sendForm={sendForm}
      />
    </Container>
  );
}

export default CreateProduct;
