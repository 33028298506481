import React from "react";
import ReactBnbGallery from "react-bnb-gallery";

function Gallery({ isOpen, setIsOpen, photos, activePhotoIndex }) {
  return (
    <ReactBnbGallery
      show={isOpen}
      photos={photos}
      onClose={() => setIsOpen(false)}
      activePhotoIndex={activePhotoIndex}
    />
  );
}

export default Gallery;
