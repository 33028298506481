import React, { useEffect, useState, useContext } from "react";
import { Button, Spinner, Alert, Modal } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import {
  Elements,
  useElements,
  useStripe,
  PaymentElement,
  // PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Stripe.css";

// Firebase
import { httpsCallable } from "firebase/functions";

// Services
import {
  functions,
  homeIsAvailable,
  updateBooking,
} from "../../../services/Firebase";
import { STRIPE_PUBLISHABLE_KEY } from "../../../other/Keys";
import { UserContext } from "../../../other/Contexts";
import { getFullUserName } from "../../../other/Utilities";

// Stripe
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function PaymentForm({ booking }) {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  // Stripe
  const elements = useElements();
  const stripe = useStripe();
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const [error, setError] = useState("");

  /////////////////////////
  // Payment Form Submit //
  /////////////////////////

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaymentInProgress(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      paymentInProgress(false);
      return;
    }

    if (!(await homeIsAvailable(booking))) {
      // Home is not available anymore for the given booking dates.
      setError(
        "The selected dates for this booking duration are not available anymore!"
      );
      setPaymentInProgress(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: "https://superbcamping.com/checkout-status/" + home.id,
        // return_url: "http://localhost:3000/checkout-status/" + booking.home.id,
        return_url: "https://superbcamping.com/account/booking/" + booking.id,
        // return_url: "http://localhost:3000/account/booking/" + booking.id,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setError(error.message);
    } else {
      setError("An unexpected error occurred.");
    }

    setPaymentInProgress(false);
  };

  // useEffect(() => {
  //   // Enable ApplePayButton
  //   // @see https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react

  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: "CH",
  //       currency: "eur",
  //       total: {
  //         label: "SuperbCamping",
  //         amount: 1000,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });
  //   }
  // }, [stripe]);

  //////////////////////
  // Helper functions //
  //////////////////////

  function isOwnHome() {
    return booking && userData && booking.home.ownerId === userData.uid;
  }

  /////////////////
  // UI Elements //
  /////////////////

  // function ApplePayElement() {
  //   if (paymentRequest) {
  //     return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  //   }
  //   return null;
  // }

  function PaymentButton() {
    return (
      <Button
        variant="dark"
        type="submit"
        disabled={paymentInProgress || isOwnHome()}
      >
        <i className="bi bi-lock-fill" style={{ color: "white" }}></i>{" "}
        {paymentInProgress ? (
          <Spinner animation="border" size="sm" />
        ) : (
          t("Pay")
        )}
      </Button>
    );
  }

  function ErrorMessages() {
    return (
      <>
        {error !== "" && (
          <Alert variant="danger" style={{ marginTop: 10 }}>
            {t(error)}
          </Alert>
        )}
        {isOwnHome() && (
          <Alert variant="warning" style={{ marginTop: 10 }}>
            {t("You are the owner of this accommodation!")}
          </Alert>
        )}
      </>
    );
  }

  function LoadingAlert() {
    return (
      <Modal show={paymentInProgress}>
        <Modal.Header>
          <Modal.Title>{t("Payment in progress ...")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ float: "left" }}>
            {t("This can take up to a couple of seconds.")}
          </p>
          <Spinner animation="border" size="md" style={{ float: "right" }} />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {/*<ApplePayElement />*/}
      <div className="payment-element-container">
        <PaymentElement />
      </div>
      <PaymentButton />
      <ErrorMessages />
      <LoadingAlert />
    </form>
  );
}

function StripeCheckOut({ i18n, booking }) {
  // Create Payment Intent (required for displaying the Payment Form)
  const [paymentIntent, setPaymentIntent] = useState(null);
  const createStripePaymentIntent = httpsCallable(
    functions,
    "createStripePaymentIntent"
  );
  const userData = useContext(UserContext);

  useEffect(() => {
    if (booking !== null && userData !== null) {
      const createData = async () => {
        await updateBooking(booking.id, {
          camper: {
            customerId: userData.customerId,
            email: userData.email,
            userId: userData.uid,
            fullUserName: getFullUserName(userData),
          },
        });

        createStripePaymentIntent({ bookingId: booking.id })
          .then((result) => {
            setPaymentIntent(result.data.payment_intent);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      createData();
    }
  }, [booking]);

  if (paymentIntent !== null) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: paymentIntent.client_secret,
          locale: i18n.resolvedLanguage,
        }}
      >
        <PaymentForm booking={booking} />
      </Elements>
    );
  } else {
    return (
      <>
        <div className="payment-element-container"></div>
        <Button variant="dark">
          <i className="bi bi-lock-fill" style={{ color: "white" }}></i>{" "}
          <Spinner animation="border" size="sm" />
        </Button>
      </>
    );
  }
}

export default withTranslation()(StripeCheckOut);
export { stripePromise };
