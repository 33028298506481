import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  ListGroup,
  Col,
  Image,
  Carousel,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import "./HomeStackList.css";

// Services
import { searchHomes } from "../../../services/Firebase";
import {
  styles,
  getHomeTitle,
  getHomeAddressString,
  getUserFacingPrice,
  translate_text,
} from "../../../other/Utilities";

function HomeListEntry(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [homeDescription, setHomeDescription] = useState("");
  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: ["EUR"],
  });

  function handleClick() {
    navigate(`/explore/${props.home.id}`);
  }

  useEffect(() => {
    translate_text(
      props.home.description,
      i18n.resolvedLanguage,
      setHomeDescription
    );
  }, [props.home.description, i18n.resolvedLanguage]);

  return (
    <Container fluid style={styles.container} className="homeContainer">
      <Row style={styles.row}>
        <Col style={styles.col} md="auto" sm="auto" xs="auto">
          <Carousel interval={null} className="homeImageCarousel">
            {props.home.thumbnailLinks.sort().map((imageLink) => {
              return (
                <Carousel.Item
                  key={imageLink}
                  interval={null}
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Image
                    fluid
                    src={imageLink}
                    style={{
                      width: "100%",
                      height: "200px",
                      borderRadius: 10,
                      objectFit: "cover",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
        <Col style={styles.col} onClick={handleClick} className="homeEntry">
          <p>
            <span
              className="truncate"
              style={{
                float: "left",
                fontWeight: "bold",
                maxWidth: "calc(100% - 70px)",
              }}
            >
              {getHomeTitle(t, props.home)}
            </span>
            <span
              style={{
                float: "right",
                backgroundColor: "green",
                padding: "2px 6px 2px 6px",
                borderRadius: 2,
                color: "white",
              }}
            >
              {getUserFacingPrice(props.home.price, i18n.resolvedLanguage, 0)}{" "}
              {currency}
            </span>
          </p>
          <p className="truncate" style={{ height: 120 }}>
            {homeDescription}
          </p>
          <p
            className="truncate"
            style={{ color: "gray", fontSize: 13, height: 19 }}
          >
            <i className="bi-geo" style={{ fontSize: 13 }}></i>{" "}
            {getHomeAddressString(props.home.address)}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function HomeStackList({ searchParams, setParentHomes }) {
  const [homes, setHomes] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    searchHomes(searchParams, setHomes, setParentHomes, setIsLoading);
  }, [searchParams]);

  return (
    <ListGroup variant="flush">
      <ListGroup.Item key={0} style={{ color: "gray" }}>
        {t("Number of Results")}: {homes.length}
      </ListGroup.Item>
      {isLoading ? (
        <Spinner
          animation="border"
          size="md"
          style={{ marginLeft: "50%", marginTop: 20 }}
        />
      ) : (
        homes.map((home) => {
          return (
            <ListGroup.Item key={home.id}>
              <HomeListEntry home={home} />
            </ListGroup.Item>
          );
        })
      )}
    </ListGroup>
  );
}

export default HomeStackList;
