import React from "react";
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import { withTranslation } from "react-i18next";
import moment from "moment";

function MultiDatePicker({
  i18n,
  dates,
  setDates,
  numberOfMonths,
  blockedDates,
}) {
  function handleDateChange(date) {
    const wasPreviouslyPicked = dates.some((d) => d.isSame(date));
    if (wasPreviouslyPicked) {
      setDates((previousDates) => previousDates.filter((d) => !d.isSame(date)));
    } else {
      setDates((previousDates) => [...previousDates, date]);
    }
  }

  function isBlocked(day) {
    return blockedDates.some((date) => day.isSame(moment(date), "day"));
  }

  return (
    <DayPickerSingleDateController
      onDateChange={handleDateChange}
      focused={true}
      numberOfMonths={numberOfMonths}
      date={null}
      isDayHighlighted={(day) => dates.some((d) => d.isSame(day, "day"))}
      keepOpenOnDateSelect
      hideKeyboardShortcutsPanel={true}
      isOutsideRange={(day) => day.isBefore(moment())}
      isDayBlocked={isBlocked}
      renderMonthElement={({ month }) =>
        moment(month).locale(i18n.resolvedLanguage).format("MMMM YYYY")
      }
    />
  );
}
export default withTranslation()(MultiDatePicker);
