import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";

import { currencyList } from "../../other/Constants";

function CurrencyDropdown({ t }) {
  const [currency, setCurrency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  return (
    <Dropdown variant="secondary" size="sm">
      <Dropdown.Toggle
        variant="secondary"
        style={{
          background: "var(--light-gray)",
          color: "black",
          border: "none",
          padding: 0,
          marginTop: -5,
        }}
      >
        {currency}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {currencyList.map((curr) => {
          return (
            <Dropdown.Item key={{ curr }} onClick={() => setCurrency(curr)}>
              {t(curr)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default withTranslation()(CurrencyDropdown);
