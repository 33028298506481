import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { withTranslation } from "react-i18next";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop({ t, files, setFiles, maxFiles }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: "image/*",
      //maxFiles: maxFiles,
      onDrop: (acceptedFiles) => {
        var tempList = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        // Filter out duplicate images
        tempList = files.concat(tempList);
        tempList = tempList.filter(
          (v, i, a) => a.findIndex((v2) => v2.path === v.path) === i
        );

        // Limit the number of allowed files
        // TODO: Find Bug
        // const totalNumberOfFiles = tempList.length + files.length;
        // if (totalNumberOfFiles > maxFiles) {
        //     console.log("totalNumberOfFiles - maxFiles", totalNumberOfFiles - maxFiles)
        //     for (let i = 0; i < (totalNumberOfFiles - maxFiles); i++) {
        //         console.log("i", i)
        //         tempList.pop();
        //     }
        // }
        // console.log("tempList.length", tempList.length)

        setFiles(tempList);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name + ", " + index}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="preview" />
      </div>
      <div
        style={{
          position: "absolute",
          height: 30,
          width: 30,
          borderRadius: 30,
          marginLeft: 70,
          marginTop: -10,
          border: "1px solid #eaeaea",
          cursor: "pointer",
          backgroundColor: "white",
          textAlign: "center",
        }}
        onClick={() => {
          removeFile(index);
        }}
      >
        <i className="bi-x" style={{ fontSize: 20 }}></i>
      </div>
    </div>
  ));

  function removeFile(index) {
    var array = [...files];
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array);
    }
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t("Drag 'n' drop some files here, or click to select files.")}</p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

export default withTranslation()(DragAndDrop);
