import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

// Views //

import IndexPage from "./views/main/IndexPage";

// Explore
import ExploreView from "./views/main/explore/ExploreView";
import HomeView from "./views/main/explore/HomeView";

// Checkout
import CheckoutView from "./views/main/checkout/CheckoutView";
import CheckoutStatusView from "./views/main/checkout/CheckoutStatusView";

// Account
import LoginView from "./views/main/account/LoginView";
import RegisterView from "./views/main/account/RegisterView";
import ResetPassword from "./views/main/account/ResetPassword";
import AuthWrapper from "./views/shared/AuthWrapper";
import AccountView from "./views/main/account/AccountView";
import EditAccount from "./views/main/account/EditAccount";
import HomeDashboard from "./views/main/account/HomeDashboard";
import CreateHome from "./views/main/account/CreateHome";
import EditHome from "./views/main/account/EditHome";
import CreateProduct from "./views/main/account/CreateProduct";
import EditProduct from "./views/main/account/EditProduct";
import TravelsListView from "./views/main/account/TravelsListView";
import BookingView from "./views/main/account/BookingView";
import VerifyBookingView from "./views/main/account/VerifyBookingView";
import ConnectToStripe from "./views/main/account/ConnectToStripe";
import UnsubscribeEmails from "./views/main/account/UnsubscribeEmails";

// Other
import FAQs from "./views/main/other/FAQs";
import Impressum from "./views/main/other/Impressum";
import PrivacyPolicy from "./views/main/other/PrivacyPolicy";
import TermsOfService from "./views/main/other/TermsOfService";
import NotFound from "./views/shared/NotFound";

// Components //

import NavigationBar from "./views/shared/NavigationBar";
import Footer from "./views/shared/Footer";
import ScrollToTop from "./views/shared/ScrollToTop";

// Services //

import { auth, getUserData } from "./services/Firebase";
import { UserContext } from "./other/Contexts";

function App() {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Make userData available to all sub-pages without requiring a reload from firebase every time.
    user ? getUserData(user.uid, setUserData) : setUserData(null);
  }, [user]);

  return (
    <Router>
      <UserContext.Provider value={userData}>
        <ScrollToTop />
        <nav>
          <NavigationBar />
        </nav>
        <Routes>
          {/* Split up the IndexPage and the MainLayoutRoutes due to different styling of the Index Page (Parallax Effect) */}
          <Route path="/" element={<IndexPage />} />
          <Route path="*" element={<MainLayoutRoutes />} />
        </Routes>
      </UserContext.Provider>
    </Router>
  );
}

function MainLayoutRoutes() {
  return (
    <>
      <React.Fragment />
      <Routes>
        <Route path="/explore" element={<ExploreView />} />
        <Route path="/explore/:homeId" element={<HomeView />} />
        <Route path="/checkout/:bookingId" element={<CheckoutView />} />
        <Route
          path="/checkout-status/:bookingId"
          element={<CheckoutStatusView />}
        />

        <Route path="/account/login" element={<LoginView />} />
        <Route path="/account/register" element={<RegisterView />} />
        <Route path="/account/reset-password" element={<ResetPassword />} />
        <Route element={<AuthWrapper />}>
          <Route path="/account" element={<AccountView />} />
          <Route path="/account/edit" element={<EditAccount />} />
          <Route
            path="/account/home-dashboard/:homeId"
            element={<HomeDashboard />}
          />
          <Route path="/account/create-home" element={<CreateHome />} />
          <Route path="/account/edit-home/:homeId" element={<EditHome />} />
          <Route path="/account/create-product" element={<CreateProduct />} />
          <Route
            path="/account/edit-product/:productId"
            element={<EditProduct />}
          />
          <Route path="/account/travels" element={<TravelsListView />} />
          <Route path="/account/booking/:bookingId" element={<BookingView />} />
          <Route
            path="/account/connect-to-stripe"
            element={<ConnectToStripe />}
          />
          <Route
            path="/account/unsubscribe-emails"
            element={<UnsubscribeEmails />}
          />
          <Route
            path="/account/verify-booking/:bookingId"
            element={<VerifyBookingView />}
          />
        </Route>

        <Route path="/faqs" element={<FAQs />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <footer>
        <Footer />
      </footer>
      <React.Fragment />
    </>
  );
}

export default App;
