import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Container } from "react-bootstrap";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Services
import { auth } from "../../services/Firebase";

function AuthWrapper() {
  const location = useLocation();
  const [user, loading] = useAuthState(auth);

  if (loading) return <Container className="mainContainer"></Container>;

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/account/login" replace state={{ from: location }} />
  );
}

export default AuthWrapper;
