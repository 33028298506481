import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";

function LanguageDropdown({ t, i18n }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Dropdown variant="secondary" size="sm">
      <Dropdown.Toggle
        variant="secondary"
        style={{
          background: "var(--light-gray)",
          color: "black",
          border: "none",
          padding: 0,
          marginTop: -5,
        }}
      >
        {t("language." + i18n.resolvedLanguage)}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage("en")}>
          {t("language.en")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("de")}>
          {t("language.de")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default withTranslation()(LanguageDropdown);
