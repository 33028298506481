import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Alert,
  Form,
} from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import useSessionStorageState from "use-session-storage-state";
import moment from "moment";
import "./HomeView.css";

// Components
import DatePicker from "../../shared/DatePicker";

// Services
import { getBookingsForHome, createBooking } from "../../../services/Firebase";
import { limitations } from "../../../other/Constants";
import {
  styles,
  useWindowSize,
  diffDays,
  getUserFacingPrice,
  getLimitationIcon,
  getDaysArray,
  BookingStatus,
} from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function HomeBookingSection({ home }) {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userData = useContext(UserContext);

  // Form (all these parameters are stored for the whole session, so that the user does not need to enter them again after checking out a new home)
  const [startDate, setStartDate] = useSessionStorageState("startDate", {
    defaultValue: null,
  });
  const [endDate, setEndDate] = useSessionStorageState("endDate", {
    defaultValue: null,
  });
  const [adults, setAdults] = useSessionStorageState("adults", {
    defaultValue: 2,
  });
  const [children, setChildren] = useSessionStorageState("children", {
    defaultValue: 0,
  });
  const [limitationsCheckedState, setLimitationsCheckedState] = useState(
    new Array(limitations.length).fill(false)
  ); // The vehicle is not stored in the sessionStorage since it varies a lot between the different homes.

  const nights = diffDays(
    startDate != null ? moment(startDate) : null,
    endDate != null ? moment(endDate) : null
  );

  // To display disabled dates in the calendar
  const [homeBookings, setHomeBookings] = useState([]);
  const [, setHomeBookingsLoading] = useState(true);
  const [disabledDates, setDisabledDates] = useState([]);

  function bookingIsValid() {
    if (
      startDate != null &&
      endDate != null &&
      !isOwnHome() &&
      isPublicHome() &&
      limitationsCheckedState.includes(true)
    ) {
      return true;
    }
    return false;
  }

  function isOwnHome() {
    return userData && home && home.ownerId === userData.uid;
  }

  function isPublicHome() {
    return home && home.reviewStatus === 2 && home.activeState;
  }

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  useEffect(() => {
    if (home) {
      setDisabledDates([
        ...disabledDates,
        ...home.disabledDates.map((element) => moment(element.toDate(), "day")),
      ]);
    }
  }, [home]);

  useEffect(() => {
    home &&
      getBookingsForHome(home.id, setHomeBookings, setHomeBookingsLoading);
  }, [home]);

  useEffect(() => {
    if (homeBookings) {
      var tempArray = [];
      for (let i = 0; i < homeBookings.length; i++) {
        var startDate = homeBookings[i].startDate.toDate();
        var endDate = homeBookings[i].endDate.toDate();
        tempArray = tempArray.concat(getDaysArray(startDate, endDate));
      }
      setDisabledDates([
        ...disabledDates,
        ...tempArray.map((element) => moment(element, "day")),
      ]);
    }
  }, [homeBookings]);

  async function goToCheckout() {
    let booking = {
      adults: adults,
      amount: nights * home.price,
      bookingDate: new Date(),
      children: children,
      currency: currency,
      endDate: new Date(endDate),
      home: home,
      nights: nights,
      status: BookingStatus.new,
      startDate: new Date(startDate),
      vehicle: {
        caravan: limitationsCheckedState[0],
        tent: limitationsCheckedState[1],
        car: limitationsCheckedState[2],
      },
    };

    let bookingId = await createBooking(booking);
    navigate(`/checkout/${bookingId}`);
  }

  function ErrorMessages() {
    return (
      <>
        {isOwnHome() && (
          <Row>
            <Col>
              <Alert variant="warning">
                {t("You are the owner of this accommodation!")}
              </Alert>
            </Col>
          </Row>
        )}
        {!isPublicHome() && (
          <Row>
            <Col>
              <Alert variant="warning">
                {t("This accommodation is not available for booking!")}
              </Alert>
            </Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <Container
      fluid
      style={{ border: "1px solid lightgray", borderRadius: 10, padding: 15 }}
    >
      <Row>
        <Col>
          <DatePicker
            startDate={startDate != null ? moment(startDate) : null}
            setStartDate={setStartDate}
            endDate={endDate != null ? moment(endDate) : null}
            setEndDate={setEndDate}
            blockedDates={home ? disabledDates : []}
            maxNights={home ? home.limitations.maxNights : 1}
            numberOfMonths={width > 767 ? 2 : 1}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <SelectGuestsView
            adults={adults}
            setAdults={setAdults}
            children={children}
            setChildren={setChildren}
            maxPersons={home != null ? home.limitations.maxPersons : 2}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <SelectVehicleView
            limitationsCheckedState={limitationsCheckedState}
            setLimitationsCheckedState={setLimitationsCheckedState}
            homeLimitations={home && home.limitations}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10, marginBottom: 5 }}>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ marginTop: 8 }}>
            {t("Total Price")}:{" "}
            <b>
              {home &&
                getUserFacingPrice(
                  nights * home.price,
                  i18n.resolvedLanguage
                )}{" "}
              {currency}
            </b>
          </p>
        </Col>
        <Col>
          <Button
            variant="primary"
            disabled={!bookingIsValid()}
            style={{ float: "right" }}
            onClick={() => goToCheckout()}
          >
            {t("Next")}
          </Button>
        </Col>
      </Row>
      <ErrorMessages />
    </Container>
  );
}

function SelectGuestsView({
  adults,
  setAdults,
  children,
  setChildren,
  maxPersons,
}) {
  const { t } = useTranslation();
  return (
    <Container fluid style={styles.container}>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <h4>{t("How many guests?")}</h4>
          <p style={{ color: "gray" }}>
            {t("At this accommodation a maximum of %@ guests are allowed.", {
              val: maxPersons,
            })}
          </p>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <p style={{ marginTop: 8 }}>
            {t("Adults")} {adults}
          </p>
        </Col>
        <Col style={styles.col}>
          <p style={{ float: "right" }} className="selectGuestButtons">
            <Button
              variant="light"
              onClick={() => {
                adults > 1 && setAdults(adults - 1);
              }}
            >
              -
            </Button>{" "}
            <Button
              variant="light"
              onClick={() => {
                adults + children < maxPersons && setAdults(adults + 1);
              }}
            >
              +
            </Button>
          </p>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <p style={{ marginTop: 8 }}>
            {t("Children (Age 1-12):")} {children}
          </p>
        </Col>
        <Col style={styles.col}>
          <p style={{ float: "right" }} className="selectGuestButtons">
            <Button
              variant="light"
              onClick={() => {
                children > 0 && setAdults(children - 1);
              }}
            >
              -
            </Button>{" "}
            <Button
              variant="light"
              onClick={() => {
                adults + children < maxPersons && setChildren(children + 1);
              }}
            >
              +
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function SelectVehicleView({
  limitationsCheckedState,
  setLimitationsCheckedState,
  homeLimitations,
}) {
  const { t } = useTranslation();
  return (
    <Container fluid style={styles.container}>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <h4>{t("How are you traveling?")}</h4>
          <p style={{ color: "gray" }}>
            {t(
              "Select all the options which apply to you (multiple selections are possible)."
            )}
          </p>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <Stack direction="horizontal" gap={3}>
            {limitations.map((limitation, position) => (
              <Form.Check
                label={
                  <>
                    {" "}
                    {getLimitationIcon(limitation)}{" "}
                    {t("limitations." + limitation)}{" "}
                  </>
                }
                type="switch"
                id={limitation}
                key={limitation}
                checked={limitationsCheckedState[position]}
                onChange={() =>
                  setLimitationsCheckedState(
                    limitationsCheckedState.map((item, index) =>
                      index === position ? !item : item
                    )
                  )
                }
                disabled={!homeLimitations || !homeLimitations[limitation]}
              />
            ))}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(HomeBookingSection);
