import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";

function TermsOfService({ t, i18n }) {
  const lastUpdated = new Date("18 July, 2020");

  return (
    <Container className="mainContainer" style={{ maxWidth: 1040 }}>
      <Heading text={"Terms of Service"} />
      <Row>
        <Col>
          <p style={{ color: "gray" }}>
            {t("Last updated")}:{" "}
            {lastUpdated.toLocaleDateString(i18n.resolvedLanguage)}
          </p>
          <p>
            SuperbCamping and/or its affiliates (“SuperbCamping”, “we” or “us”)
            operate an online store (“SuperbCamping Shop”) located at{" "}
            <a href="https://superbcamping.com">superbcamping.com</a> from which
            you may purchase products and services (“SuperbCamping Shop
            Products”).
          </p>
          <p>
            These Terms of Use apply to the content and functionality of the
            SuperbCamping Shop, and to the SuperbCamping Shop Products. These
            Terms of Use do not apply to products or services that we make
            available pursuant to different terms, such as our payment
            processing services, which are governed by the{" "}
            <a href="https://stripe.com/ssa">Stripe Services Agreement</a>.
          </p>
          <h5 id="information-that-you-provide-to-us">
            1. Information that you provide to us
          </h5>
          <p>
            You may give us information about yourself when you visit the
            SuperbCamping Shop. Our{" "}
            <a href="privacyPolicy.html">Privacy Policy</a> explains our
            practices with respect to that information. We may need to send you
            email and text messages in order to, for example, verify your
            identity or provide you with important information. You authorize us
            to send those messages when you visit the SuperbCamping Shop and
            provide your contact details. Standard text or data charges may
            apply to text messages. Where offered, you may disable text message
            notifications by responding to any such message with “STOP”, or by
            following instructions provided in the message. However, by
            disabling text messaging, you may be disabling important security
            controls and may increase the risk of loss to your business.
          </p>
          <h5 id="stripe-shop-ip">2. SuperbCamping Shop IP</h5>
          <p>
            As between you and SuperbCamping, SuperbCamping and its licensors
            exclusively own all rights, title, and interest in the patents,
            copyrights (including rights in derivative works), moral rights,
            rights of publicity, trademarks or service marks, logos and designs,
            trade secrets, and other intellectual property embodied by, or
            contained in the SuperbCamping Shop (collectively, “SuperbCamping
            Shop IP”). SuperbCamping Shop IP is protected by copyright, trade
            secret, patent, and other intellectual property laws, and all rights
            in SuperbCamping Shop IP not expressly granted to you in these Terms
            of Use are reserved.
          </p>
          <p>
            You may choose to or we may invite you to submit comments or ideas
            about improvements to the SuperbCamping Shop or our products or
            services (“Ideas”). If you submit an Idea to us, we will presume
            that your submission was voluntary, unsolicited by us, and delivered
            to us without any restrictions on our use of the Idea. You also
            agree that SuperbCamping has no fiduciary or any other obligation to
            you in connection with any Idea you submit to us, and that we are
            free to use your Ideas without any attribution or compensation to
            you.
          </p>
          <h5 id="access-to-stripe-shop">3. Access to SuperbCamping Shop</h5>
          <p>
            SuperbCamping grants you a limited, revocable, non-exclusive,
            non-transferable license to access the SuperbCamping Shop. This
            license does not include a right to use any of the content and
            information, including product listings. Your permissions and/or
            licenses are automatically terminated by any unauthorized use.
          </p>
          <h5 id="products-content-and-specifications">
            4. Products, Content and Specifications.
          </h5>
          <p>
            Details of the products and services available for purchase in the
            SuperbCamping Shop (“SuperbCamping Shop Products”) are set out in
            the SuperbCamping Shop. All features, content, specifications,
            products and prices of products and services described or depicted
            in this SuperbCamping Shop are subject to change at any time without
            notice. Unless expressly noted, all weights, measures and similar
            descriptions are approximate and are provided for convenience
            purposes only. Packaging may vary from that shown, and the
            appearance of a product in reality may differ from its appearance to
            you on the SuperbCamping Shop due to the limitations of the systems
            that you use to access the SuperbCamping Shop. The inclusion of any
            products or services in the SuperbCamping Shop at a particular time
            does not imply or warrant that these products or services will be
            available at any time. Occasionally, the manufacture or distribution
            of a certain product or service may be delayed for a number of
            reasons. In such event, we will make reasonable efforts to notify
            you of the delay and keep you informed of the revised delivery
            schedule. By placing an order, you represent that the products
            ordered will be used only in a lawful manner.
          </p>
          <h5 id="your-account">5. Your account</h5>
          <p>
            We may require that you create an account to access the
            SuperbCamping Shop, including to make purchases. If we do so, you
            must provide accurate information about yourself when you create an
            account and ensure that you update us if that information changes.
            You must ensure that your login details remain confidential. You are
            responsible for any activity, including any purchases made, under
            your account. We reserve the right to terminate your account at any
            time and for any reason.
          </p>
          <h5 id="sanctions-and-export-policy">
            6. Sanctions and export policy
          </h5>
          <p>
            You may not use the SuperbCamping Shop or purchase any SuperbCamping
            Shop Product in or for the benefit of a country, organization,
            entity, or person embargoed or blocked by any government, including
            those on sanctions lists identified by the{" "}
            <a href="https://www.treasury.gov/resource-center/sanctions">
              United States Office of Foreign Asset Control (OFAC)
            </a>
            . We do not claim, and we cannot guarantee that the SuperbCamping
            Shop or any SuperbCamping Stripe Shop Product is or will be
            appropriate or available for any location or jurisdiction, comply
            with the laws of any location or jurisdiction, or comply with laws
            governing export, import, or foreign use.
          </p>
          <h5 id="no-warranties">7. No warranties</h5>
          <p>
            <strong>
              We provide the SuperbCamping Shop, SuperbCamping Shop IP and
              SuperbCamping Shop Products “as is” and “as available”, without
              any express, implied, or statutory warranties of title,
              merchantability, fitness for a particular purpose,
              noninfringement, or any other type of condition, warranty or
              guarantee. No data, documentation or any other information
              provided by SuperbCamping or obtained by you from or through the
              SuperbCamping Shop – whether from SuperbCamping or another entity,
              and whether oral or written – creates or implies any warranty from
              SuperbCamping to you.
            </strong>
          </p>
          <p>
            <strong>
              SuperbCamping disclaims any knowledge of, and does not guarantee:
              (a) the accuracy, reliability, or correctness of any data provided
              through the SuperbCamping Shop; (b) that the SuperbCamping Shop
              Products will meet your specific needs or requirements; (c) that
              the SuperbCamping Shop will be available at any particular time or
              location, or will function in an uninterrupted manner or be
              secure; (d) that SuperbCamping will correct any defects or errors
              in the SuperbCamping Shop; or (e) that the SuperbCamping Shop is
              free of viruses or other harmful code. Use of data, products or
              services that you access, purchase or download through the
              SuperbCamping Shop is done at your own risk – you are solely
              responsible for any damage to your property, loss of data, or any
              other loss that results from such access, purchase or download.
            </strong>
          </p>
          <p>
            <strong>
              Nothing in these Terms of Use operates to exclude, restrict or
              modify the application of any implied condition, warranty or
              guarantee, or the exercise of any right or remedy, or the
              imposition of any liability under law to the extent that doing so
              would: (a) contravene that law; or (b) cause any term of this
              agreement to be void.
            </strong>
          </p>
          <h5 id="limitation-of-liability">8. Limitation of liability</h5>
          <p>
            Under no circumstances will SuperbCamping be responsible or liable
            to you for any indirect, punitive, incidental, special,
            consequential, or exemplary damages resulting from your use or
            inability to use the SuperbCamping Shop or for the unavailability of
            the SuperbCamping Shop, or for lost profits, personal injury, or
            property damage, or for any other damages arising out of, in
            connection with, or relating to these Terms of Use or your use of
            the SuperbCamping Shop, even if such damages are foreseeable, and
            whether or not you or the SuperbCamping has been advised of the
            possibility of such damages. SuperbCamping is not liable, and denies
            responsibility for, any damages, harm, or losses to you arising from
            or relating to hacking, tampering, or other unauthorized access or
            use of the SuperbCamping Shop or your failure to use or implement
            anti-fraud measures, security controls, or any other data security
            measure. SuperbCamping further denies responsibility for all
            liability and damages to you or others caused by (a) your access or
            use of the SuperbCamping Shop inconsistent with our instructions;
            (b) any unauthorized access of servers, infrastructure, or data used
            in connection with the SuperbCamping Shop; (c) any bugs, viruses, or
            other harmful code that may be transmitted to or through the
            SuperbCamping Shop; (d) any errors, inaccuracies, omissions, or
            losses in or to any data provided to us; (e) third-party content
            provided by you; or (f) the defamatory, offensive, or illegal
            conduct of others.
          </p>
          <p>
            You agree to limit any additional liability not disclaimed or denied
            by SuperbCamping in relation to the SuperbCamping Shop,
            SuperbCamping Shop IP, and SuperbCamping Shop Products, to your
            direct and documented damages; and you further agree that under no
            circumstances will any such liability exceed in the aggregate the
            greater of the amounts paid by you to SuperbCamping during the
            three-month period immediately preceding the event that gave rise to
            your claim for damages, and EUR €20.
          </p>
          <p>
            These limitations on our liability to you will apply regardless of
            the legal theory on which your claim is based, including contract,
            tort (including negligence), strict liability, or any other theory
            or basis.
          </p>
          <h5 id="disputes">9. Disputes</h5>
          <p>
            <strong>a. Binding Arbitration:</strong> In the event that there is
            a dispute, claim or controversy arising out of or relating to
            statutory or common law claims, the breach, termination,
            enforcement, interpretation or validity of any provision of these
            Terms of Use, and the determination of the scope or applicability of
            your agreement to arbitrate any dispute, claim or controversy
            originating from these Terms of Use, but specifically excluding any
            dispute principally related to either party’s intellectual property
            (which such dispute will be resolved in litigation before the
            Austrian Court of Justice), will be determined by arbitration in
            Austria before a single arbitrator. The arbitration will be
            administered by the Austrian Arbitration Association under its
            Commercial Arbitration Rules. The Expedited Procedures of the
            Austrian Arbitration Association’s Commercial Arbitration Rules will
            apply for cases in which no disclosed claim or counterclaim exceeds
            $75,000 (exclusive of interest, attorneys’ fees and arbitration fees
            and costs). Where no party’s claim exceeds $25,000 (exclusive of
            interest, attorneys’ fees and arbitration fees and costs), and in
            other cases in which the parties agree, Section E-6 of the Expedited
            Procedures of the Austrian Arbitration Association’s Commercial
            Arbitration Rules will apply. The arbitrator will apply the
            substantive law of Austrian, exclusive of its conflict or choice of
            law rules. If the Austrian Arbitration Association is no longer in
            business, or refuses or declines to administer any dispute between
            the parties brought before it, either party may petition the
            Austrian Court to appoint the arbitrator. Nothing in this paragraph
            will preclude the parties from seeking provisional remedies in aid
            of arbitration from a court of appropriate jurisdiction. The parties
            acknowledge that this Agreement evidences a transaction involving
            interstate commerce. Notwithstanding the provisions in this
            paragraph referencing applicable substantive law, the Federal
            Arbitration Act (9 U.S.C. §§ 1-16) will govern any arbitration
            conducted pursuant to these Terms of Use.
          </p>
          <p>
            Either party may commence arbitration by providing to the Austrian
            Arbitration Association and the other party to the dispute a written
            demand for arbitration, setting forth the subject of the dispute and
            the relief requested.
          </p>
          <p>
            <strong>b. Service of Process:</strong> Each party hereby
            irrevocably and unconditionally consents to service of process
            through personal service at their corporate headquarters, registered
            address, or primary address (for individuals or sole proprietors).
            Nothing in these Terms of Use will affect the right of any party to
            serve process in any other manner permitted by law.
          </p>
          <p>
            <strong>c. Class Waiver:</strong> To the fullest extent permitted by
            law, each of the parties agrees that any dispute arising out of or
            in connection with these Terms of Use, whether in arbitration or in
            court, will be conducted only on an individual basis and not in a
            class, consolidated or representative action. If for any reason a
            claim or dispute proceeds in court rather than through arbitration,
            each party knowingly and irrevocably waives any right to trial by
            jury in any action, proceeding or counterclaim arising out of or
            relating to these Terms of Use or any of the transactions
            contemplated between the parties.
          </p>
          <p>
            <strong>d. Provision of an Award:</strong> Subject to the
            limitations of liability identified in these Terms of Use, the
            appointed arbitrators may award monetary damages and any other
            remedies allowed by the laws of Austria. In making a determination,
            the arbitrator will not have the authority to modify any term or
            provision of this Agreement. The arbitrator will deliver a reasoned
            written decision with respect to the dispute (the “Award”) to each
            party, who will promptly act in accordance with the Award. Any Award
            (including interim or final remedies) may be confirmed in or
            enforced by the Austrian court. The decision of the arbitrator will
            be final and binding on the parties, and will not be subject to
            appeal or review.
          </p>
          <p>
            <strong>e. Fees:</strong> Each party will advance one-half of the
            fees and expenses of the arbitrators, the costs of the attendance of
            the arbitration reporter at the arbitration hearing, and the costs
            of the arbitration facility. In any arbitration arising out of or
            related to these Terms of Use, the arbitrators will award to the
            prevailing party, if any, the costs and attorneys’ fees reasonably
            incurred by the prevailing party in connection with those aspects of
            its claims or defenses on which it prevails, and any opposing awards
            of costs and legal fees awards will be offset.
          </p>
          <p>
            <strong>f. Confidentiality:</strong> The parties will maintain the
            confidential nature of the arbitration proceeding, the hearing and
            the Award, except (i) as may be necessary to prepare for or conduct
            the arbitration hearing on the merits, (ii) in connection with a
            court application as contemplated above for a preliminary remedy, or
            confirmation of an Award or its enforcement, (iii) our disclosure of
            the Award in confidential settlement negotiations, or (iv) as
            otherwise required by applicable laws. The parties, witnesses, and
            arbitrator will treat as confidential and will not disclose to any
            third person (other than witnesses or experts) any documentary or
            other evidence produced in any arbitration hereunder, except as
            required by law or except if such evidence was obtained from the
            public domain or was otherwise obtained independently from the
            arbitration.
          </p>
          <p>
            <strong>g. Conflict of Rules:</strong> In the case of a conflict
            between the provisions of this Section 9 and the rules governing
            arbitration identified in Section 9.a, the provisions of this
            Section 9 will prevail. If any provision of these Terms of Use to
            arbitrate is held invalid or unenforceable, it will be so held to
            the minimum extent required by law and all the other provisions will
            remain valid and enforceable.
          </p>
          <h5 id="applicable-law">10. Applicable law</h5>
          <p>
            By using the SuperbCamping Shop, you agree that the laws of Austria,
            without regard to principles of conflict of laws, will govern these
            Terms of Use and any dispute of any sort that might arise between
            you and SuperbCamping.
          </p>
          <h5 id="modification-and-severability">
            11. Modification and severability
          </h5>
          <p>
            We have the right to change or add to the terms of these Terms of
            Use at any time, solely with prospective effect, and to change,
            delete, discontinue, or impose conditions on use of the
            SuperbCamping Shop by posting such changes on our website or any
            other website we maintain or own. You can access a copy of the
            current version of these Terms of Use on our website at any time.
            You can find out when these Terms of Use were last changed by
            checking the “Last updated” date at the top of the page.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(TermsOfService);
