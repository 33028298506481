import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";

// Images
import aws_logo from "../../../images/aws_logo.jpg";

function Impressum({ t }) {
  const info_email = "info@superbcamping.com";
  const domain = "https://www.superbcamping.com";

  return (
    <Container className="mainContainer">
      <Heading text={"Impressum"} />
      <Row>
        <Col>
          <p>
            <b>{t("appName")}</b>
          </p>
          <p>
            Philip Leindecker
            <br />
            Hinterschweigerstrasse, 2b
            <br />
            4600 Wels, {t("Austria")} <br />
          </p>
          <p>
            {t("Email")}:{" "}
            <a href={"mailto:" + info_email}>
              info
              <span style={{ display: "none" }}>
                -placeholdertext for email spam-
              </span>
              @
              <span style={{ display: "none" }}>
                -placeholdertext for email spam2-
              </span>
              superbcamping.com
            </a>
            <br />
            {t("Website")}: <a href={domain}>superbcamping.com</a>
          </p>
          <p>
            © {t("appName")} {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>{t("Partner")}</h6>
          <p>{t("The project is supported by aws First Incubator.")}</p>
          <p>
            <Image src={aws_logo} width="180px" />
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(Impressum);
