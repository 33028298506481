import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

// Services
import { STRIPE_PUBLISHABLE_KEY } from "../../../other/Keys";

// Stripe
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      console.log(paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success! Payment received.");
          // setStartDate(null);
          // setEndDate(null);
          paymentIntent.metadata !== undefined &&
            navigate("/account/booking/" + paymentIntent.metadata.bookingId);
          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return message;
};

function CheckoutStatusView({ t }) {
  return (
    <Container className="mainContainer">
      <Row>
        <Col>
          <h2>{t("Payment Status")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Elements stripe={stripePromise}>
            <PaymentStatus />
          </Elements>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(CheckoutStatusView);
