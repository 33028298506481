import React, { useState, useEffect } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import Heading from "../../shared/Heading";

// Services
import { getBooking } from "../../../services/Firebase";
import { BookingSummaryView } from "./BookingView";
import { diffDays } from "../../../other/Utilities";

function VerifyBookingStatusAlert({ booking }) {
  const { t, i18n } = useTranslation();
  if (!booking) return null;
  if (booking.status === "succeeded") {
    return (
      <Alert variant="success">
        <p>
          {t("Booked successfully on the %@.", {
            val: booking.startDate
              .toDate()
              .toLocaleDateString(i18n.resolvedLanguage),
          })}
        </p>
        <p>{t("Booked by: %@.", { val: booking.camper.email })}</p>
      </Alert>
    );
  } else {
    // TODO: Write full text for each status.
    return <Alert variant="danger">{booking.status}</Alert>;
  }
}

function VerifyBookingTimingAlert({ booking }) {
  // Display if the booking is in the past, present or future
  const { t } = useTranslation();
  const today = new Date();
  if (!booking) return null;
  if (booking.status === "succeeded") {
    if (
      booking.startDate.toDate() < today &&
      today < booking.endDate.toDate()
    ) {
      return (
        <Alert variant="success">{t("The booking is valid today!")}</Alert>
      );
    } else if (booking.endDate.toDate() < today) {
      return (
        <Alert variant="warning">
          {t("The booking was valid %@ days ago.", {
            val: diffDays(today, booking.endDate.toDate()),
          })}
        </Alert>
      );
    } else {
      return (
        <Alert variant="warning">
          {t("The booking will be valid in %@ days.", {
            val: diffDays(today, booking.startDate.toDate()),
          })}
        </Alert>
      );
    }
  }
}

function VerifyBookingView() {
  let { bookingId } = useParams();
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    bookingId && getBooking(bookingId, setBooking);
  }, [bookingId]);

  return (
    <Container className="mainContainer">
      <Heading text={"Verify Booking"} />
      <Row xs={1} md={2}>
        <Col>
          <VerifyBookingStatusAlert booking={booking} />
          <VerifyBookingTimingAlert booking={booking} />
        </Col>
        <Col>
          <BookingSummaryView booking={booking} />
        </Col>
      </Row>
    </Container>
  );
}

export default VerifyBookingView;
