import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Tabs, Tab, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import "./CheckoutView.css";

// Components
import Heading from "../../shared/Heading";
import LoginForm from "../account/LoginForm";
import UserForm from "../account/UserForm";
import { BookingSummaryView } from "../account/BookingView";

// Services
import { getBooking } from "../../../services/Firebase";
import StripeCheckOut from "./Stripe";
import { getFullUserName } from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function CheckoutLoginView() {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      style={{ borderTop: "1px solid lightgray", padding: 15, minWidth: 350 }}
    >
      <Row>
        <Col>
          <h4>{t("Login or Register to proceed")}</h4>
          <Tabs defaultActiveKey="register">
            <Tab eventKey="login" title="Login" style={{ paddingTop: 20 }}>
              <LoginForm />
            </Tab>
            <Tab
              eventKey="register"
              title="Register"
              style={{ paddingTop: 20 }}
            >
              <UserForm editMode={false} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

function CheckoutPaymentView({ booking }) {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  return (
    <Container
      fluid
      style={{
        borderTop: "1px solid lightgray",
        padding: 15,
        minWidth: 350,
        marginBottom: 20,
      }}
    >
      <Row>
        <Col>
          <h4>{t("Personal Details")}</h4>
          <p>
            {t("Name")}: {userData && getFullUserName(userData)}
          </p>
          <p>
            {t("Email")}: {userData && userData.email}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>{t("Credit Card Details")}</h4>
          <div style={{ minHeight: 330 }}>
            <StripeCheckOut booking={booking} />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <Badge style={{ marginRight: 10 }} bg="warning">
            {t("Beta")}
          </Badge>
          {t("Use test credit cards from here during beta testing")}:{" "}
          <a
            href="https://stripe.com/docs/testing"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://stripe.com/docs/testing
          </a>
        </Col>
      </Row>
    </Container>
  );
}

function CheckoutView({ t }) {
  let { bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const userData = useContext(UserContext);

  useEffect(() => {
    bookingId && getBooking(bookingId, setBooking);
  }, [bookingId]);

  return (
    <Container id="mainBookingContainer">
      <Heading text={"Confirm and Pay"} backButton={true} />
      <Row>
        <Col>
          {userData ? (
            <CheckoutPaymentView booking={booking} />
          ) : (
            <CheckoutLoginView />
          )}
        </Col>
        <Col>
          <BookingSummaryView booking={booking} />
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(CheckoutView);
