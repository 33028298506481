import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image, Stack, Carousel } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import "./HomeView.css";

// Components
import Heading from "../../shared/Heading";
import Map from "../../shared/Map";
import Gallery from "../../shared/Gallery";
import HomeBookingSection from "./HomeBookingSection";

// Services
import { getHome } from "../../../services/Firebase";
import { amenities, limitations } from "../../../other/Constants";
import {
  styles,
  useWindowSize,
  getUserFacingPrice,
  getHomeTitle,
  getHomeAddressString,
  getHomeLatLngString,
  getLimitationIcon,
  getAmenityIcon,
  getSpecialInfoIcon,
  numberWithCommas,
  translate_text,
} from "../../../other/Utilities";

function HomeGalleryView({ home }) {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [width] = useWindowSize();

  function getBorderRadius(index) {
    if (index === 2 || index === 4) {
      return "0px 10px 10px 0px";
    }
    return 0;
  }

  function MobileImageView() {
    return (
      <Col style={styles.col}>
        <Carousel interval={null} style={{ height: "60vh", minHeight: 300 }}>
          {home &&
            home.imageLinks.map((imageLink, index) => {
              return (
                <Carousel.Item
                  interval={null}
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Image
                    fluid
                    key={index}
                    src={imageLink}
                    style={{
                      height: "60vh",
                      borderRadius: 10,
                      objectFit: "cover",
                      width: "100%",
                    }}
                    onClick={() => {
                      setActivePhotoIndex(index);
                      setGalleryIsOpen(true);
                    }}
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      </Col>
    );
  }

  function StandardImageView() {
    return (
      <>
        <Col style={styles.col}>
          <Image
            src={home && home.imageLinks[0]}
            style={{
              height: "60vh",
              minHeight: 300,
              maxHeight: "600px",
              borderRadius: "10px 0px 0px 10px",
              width: "calc(100% - 8px)",
            }}
            onClick={() => {
              setActivePhotoIndex(0);
              setGalleryIsOpen(true);
            }}
            className="homeImage"
          />
        </Col>
        <Col style={styles.col}>
          {[1, 2, 3, 4].map((index) => {
            if (home && index < home.imageLinks.length) {
              return (
                <Image
                  key={index}
                  src={home && home.imageLinks[index]}
                  style={{
                    height: "calc(30vh - 4px)",
                    minHeight: 146,
                    maxHeight: "296px",
                    borderRadius: getBorderRadius(index),
                    marginRight: 8,
                    marginBottom: 8,
                    width: "calc(50% - 8px)",
                  }}
                  onClick={() => {
                    setActivePhotoIndex(index);
                    setGalleryIsOpen(true);
                  }}
                  className="homeImage"
                />
              );
            } else {
              /*return (
                <Image
                  style={{ maxHeight: "296px", height: "calc(30vh - 4px)", minHeight: 146, borderRadius: getBorderRadius(index), marginRight: 8, marginBottom: 8, width: "calc(50% - 8px)" }} 
                  onClick={() => { setActivePhotoIndex(0); setGalleryIsOpen(true); }}
                  className="homeImage"
                  key={index}
                />
              );*/
              return <div key={index}></div>;
            }
          })}
        </Col>
      </>
    );
  }

  return (
    <Container fluid style={styles.container}>
      <Row style={styles.row}>
        {width < 750 ? <MobileImageView /> : <StandardImageView />}
      </Row>
      <Row>
        <Col>
          <Gallery
            photos={home && home.imageLinks.sort()}
            isOpen={galleryIsOpen}
            setIsOpen={setGalleryIsOpen}
            activePhotoIndex={activePhotoIndex}
          />
        </Col>
      </Row>
    </Container>
  );
}

function HomeView({ t, i18n }) {
  let { homeId } = useParams();
  const [home, setHome] = useState(null);
  const [homeDescription, setHomeDescription] = useState("");

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  useEffect(() => {
    getHome(homeId, setHome);
  }, [homeId]);

  function isAllowedIcon(isAllowed) {
    if (isAllowed) {
      return (
        <i className={"bi-check"} style={{ color: "green", fontSize: 18 }}></i>
      );
    } else {
      return <i className={"bi-x"} style={{ color: "red", fontSize: 18 }}></i>;
    }
  }

  useEffect(() => {
    home &&
      translate_text(
        home.description,
        i18n.resolvedLanguage,
        setHomeDescription
      );
  }, [home, i18n.resolvedLanguage]);

  return (
    <Container id="mainHomeContainer">
      <Heading text={home && getHomeTitle(t, home)} backButton={true} />
      <Row style={{ marginTop: 10 }}>
        <Col>
          <HomeGalleryView home={home} />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col>
          <Stack>
            <p>
              <i className="bi-house-fill"></i>{" "}
              {home && t("homeType." + home.homeType)}
            </p>
            <p>
              <i className="bi-chat-left-text-fill"></i>{" "}
              {home && homeDescription}
            </p>
            <p>
              <i className="bi-geo-fill"></i>{" "}
              {home && getHomeAddressString(home.address)}
            </p>
            <p>
              <i className="bi-globe"></i>{" "}
              {home && getHomeLatLngString(home.address.location)}
            </p>
            <p>
              <i className={"bi-pentagon-fill"}></i> {t("Area")}:{" "}
              {home && numberWithCommas(home.address.polygonArea)} m<sup>2</sup>
            </p>
            <p>
              <i className="bi-badge-wc-fill"></i> {t("Amenities")}:
            </p>
            <ul
              className="no-bullets"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              {amenities.map((amenity) => {
                return (
                  <li style={{ marginLeft: 10 }} key={amenity[0]}>
                    {isAllowedIcon(home && home.amenities.includes(amenity[0]))}{" "}
                    {getAmenityIcon(amenity[0])} {t("amenities." + amenity[0])}
                  </li>
                );
              })}
            </ul>
            <p>
              <i className="bi-star-fill"></i> {t("Specials")}:
            </p>
            <ul
              className="no-bullets"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              {home &&
                home.specialInfos.map((specialInfo) => {
                  return (
                    <li style={{ marginLeft: 10 }} key={specialInfo}>
                      {isAllowedIcon(true)} {getSpecialInfoIcon(specialInfo)}{" "}
                      {t("specialInfos." + specialInfo)}
                    </li>
                  );
                })}
            </ul>
            <p>
              <i className="bi-exclamation-octagon-fill"></i> {t("Limitations")}
              :
            </p>
            <ul
              className="no-bullets"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              {home &&
                limitations.map((limitation, position) => (
                  <li style={{ marginLeft: 10 }} key={limitation}>
                    {" "}
                    {isAllowedIcon(home.limitations[limitation])}{" "}
                    {getLimitationIcon(limitation)}{" "}
                    {t("limitations." + limitation)}
                  </li>
                ))}
              <li style={{ marginLeft: 10 }}>
                {t("Max. Persons")}: {home && home.limitations.maxPersons}
              </li>
              <li style={{ marginLeft: 10 }}>
                {t("Max. Nights")}: {home && home.limitations.maxNights}
              </li>
            </ul>
            <p>
              {t("Special Remarks")}: {home && home.limitations.specialRemarks}
            </p>
            <div>
              <p style={{ float: "left" }}> {t("Price")}:</p>
              <p style={{ float: "right" }}>
                <span
                  style={{
                    backgroundColor: "green",
                    padding: 5,
                    borderRadius: 5,
                    color: "white",
                  }}
                >
                  {home &&
                    getUserFacingPrice(
                      home.price,
                      i18n.resolvedLanguage,
                      0
                    )}{" "}
                  {currency}
                </span>
                /{t("Night")}
              </p>
            </div>
            <div style={{ height: 400, marginBottom: 20 }}>
              <Map homes={home ? [home] : []} zoomedMap={true} />
            </div>
          </Stack>
        </Col>
        <Col>
          <HomeBookingSection home={home} />
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(HomeView);
