import React, { useEffect, useContext } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Services
import { stripePayoutURL } from "../../../other/Constants";
import { isConnectedAccount, getAge } from "../../../other/Utilities";
import { createStripeConnectedAccount } from "../../../services/Firebase";
import { UserContext } from "../../../other/Contexts";

function ConnectToStripe({ t }) {
  const userData = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Connect to Stripe
    userData && connectToStripe();
  }, [userData]);

  function connectToStripe() {
    if (isConnectedAccount(userData)) {
      window.location.replace(stripePayoutURL);
    } else {
      if (
        userData.birthDate === undefined ||
        getAge(userData.birthDate.toDate()) < 18
      ) {
        navigate(`/account`);
      } else {
        createStripeConnectedAccount({})
          .then((result) => {
            window.location.replace(result.data.account_link);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  return (
    <Container className="mainContainer">
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h2>{t("Connecting to Stripe ...")}</h2>
          <p style={{ color: "gray" }}>{t("This can take a few seconds.")}</p>
          <Spinner animation="border" size="sm" variant="dark" />
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(ConnectToStripe);
