import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  ProgressBar,
  Spinner,
  Alert,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import countries from "i18n-iso-countries";
import useLocalStorageState from "use-local-storage-state";

// Components
import DragAndDrop from "../../shared/DragAndDrop";
import MapEdit from "../../shared/MapEdit";

// Services
import {
  countryList,
  homeTypes,
  specialInfos,
  limitations,
  amenities,
  defaultHomePrice,
} from "../../../other/Constants";
import {
  getLocationFromAddress,
  getAddressFromLocation,
  getHomeTypeIcon,
  getLimitationIcon,
  getSpecialInfoIcon,
  roundToDecimals,
  numberWithCommas,
  getAmenityIcon,
  getFullUserName,
  getFlagEmoji,
} from "../../../other/Utilities";
import { UserContext } from "../../../other/Contexts";

function HomeForm({
  t,
  i18n,
  editMode,
  progress,
  submittingInProgress,
  setSubmittingInProgress,
  isConnectedAccount,
  home,
  images,
  setImages,
  sendForm,
}) {
  const [validated, setValidated] = useState(false);

  // HomeForm Fields
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState(countryList[0][0]);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [homeTypeCheckedState, setHomeTypeCheckedState] = useState(0);
  const [specialInfosCheckedState, setSpecialInfosCheckedState] = useState(
    new Array(specialInfos.length).fill(false)
  );
  const [maxPersons, setMaxPersons] = useState(5);
  const [maxNights, setMaxNights] = useState(1);
  const [limitationsCheckedState, setLimitationsCheckedState] = useState(
    new Array(limitations.length).fill(false)
  );
  const [specialRemarks, setSpecialRemarks] = useState("");
  const [amenitiesCheckedState, setAmenitiesCheckedState] = useState(
    new Array(amenities.length).fill(false)
  );
  const [shareContactDetails, setShareContactDetails] = useState(true);
  const [price, setPrice] = useState(defaultHomePrice);
  const [publishNow, setPublishNow] = useState(true);
  const [description, setDescription] = useState("");

  // Location & Polygon
  const [marker, setMarker] = useState();
  const [polygon, setPolygon] = useState();
  const [polygonArea, setPolygonArea] = useState(0);
  const [distanceMarkerPolygon, setDistanceMarkerPolygon] = useState(0);
  const [pinToCurrentLocation, setPinToCurrentLocation] = useState(false);
  const [pinToPolygon, setPinToPolygon] = useState(false);
  const [pinToMarker, setPinToMarker] = useState(false);

  // Error Message
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const handleCloseErrorMsg = () => setShowErrorMsg(false);
  const handleShowErrorMsg = () => setShowErrorMsg(true);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(false);

  // User
  const userData = useContext(UserContext);

  useEffect(() => {
    countries.registerLocale(
      require("i18n-iso-countries/langs/" + i18n.resolvedLanguage + ".json")
    );
  }, [i18n.language]);

  useEffect(() => {
    //Fill out Address fields from Marker
    if (markerIsValid(marker)) {
      const fetchAddress = async () => {
        const address_resolved = await getAddressFromLocation(
          marker.lat,
          marker.lng
        );
        setStreet(address_resolved.street);
        setCity(address_resolved.city);
        setPostalCode(address_resolved.postal_code);
        setCountry(address_resolved.country);
      };
      fetchAddress();
    }
  }, [marker]);

  function markerIsValid(marker) {
    return marker !== undefined && marker.lat !== null && marker.lng !== null;
  }

  useEffect(() => {
    // Fill out Location from Address fields
    if (street !== "" && city !== "" && postalCode !== "") {
      const fetchLocation = async () => {
        const { lat, lng } = await getLocationFromAddress(
          street,
          city,
          postalCode,
          country
        );
        setMarker({ lat: lat, lng: lng });
        !pinToMarker && setPinToMarker(true);
      };
      // Add a debouncing effect of 800ms for user input
      const timeOutId = setTimeout(() => fetchLocation(), 800);
      return () => clearTimeout(timeOutId);
    }
  }, [street, city, postalCode, country]);

  useEffect(() => {
    if (description.length > 50) setDescriptionErrorMsg(false);
  }, [description]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (
      event.currentTarget.checkValidity() === false ||
      (images.length === 0 && !home) ||
      images.length > 50 ||
      polygon === undefined ||
      distanceMarkerPolygon > 5000 ||
      polygonArea > 1000000
    ) {
      if (description.length < 50) setDescriptionErrorMsg(true);
      handleShowErrorMsg();
      return;
    } else {
      setSubmittingInProgress(true);
      const homeNew = {
        activeState: publishNow,
        address: {
          city: city,
          country: country,
          location: {
            latitude: marker.lat,
            longitude: marker.lng,
          },
          postalCode: postalCode,
          street: street,
          polygon: polygon,
          polygonArea: roundToDecimals(polygonArea, 1),
        },
        amenities: amenities
          .map((x) => x[0])
          .filter((_, i) => amenitiesCheckedState[i]),
        description: description,
        disabledDates: [],
        homeType: homeTypes[homeTypeCheckedState],
        lastUpdate: new Date(),
        createdDate: new Date(),
        limitations: {
          car: limitationsCheckedState[2],
          tent: limitationsCheckedState[1],
          maxNights: maxNights,
          maxPersons: maxPersons,
          caravan: limitationsCheckedState[0],
          specialRemarks: specialRemarks,
        },
        ownerId: userData.uid,
        price: price,
        reviewStatus: 0, // 0: pending for review, 1: in review, 2: review success, 3: review ended in failure
        specialInfos: specialInfos.filter(
          (_, i) => specialInfosCheckedState[i]
        ),
        shareContactDetails: shareContactDetails,
        contactDetails: {},
      };
      if (editMode) {
        homeNew.disabledDates = home.disabledDates;
        homeNew.imageLinks = home.imageLinks;
        homeNew.thumbnailLinks = home.thumbnailLinks;
        homeNew.imageDates = home.imageDates;
      } else {
        homeNew.disabledDates = [];
        homeNew.imageLinks = [];
        homeNew.thumbnailLinks = [];
        homeNew.imageDates = [];
      }
      if (shareContactDetails) {
        homeNew.contactDetails.name = getFullUserName(userData);
        homeNew.contactDetails.email = userData.email;
      }
      sendForm(homeNew, images);
    }
  };

  function populateForm() {
    // Populate the Form with Home (for edit mode)
    setStreet(home.address.street);
    setCountry(home.address.country);
    setPostalCode(home.address.postalCode);
    setCity(home.address.city);
    setMarker({
      lat: home.address.location.latitude,
      lng: home.address.location.latitude,
    });
    setPinToPolygon(true);
    setPolygon(home.address.polygon);
    setPolygonArea(home.address.polygonArea);
    var homeTypesTemp = new Array(homeTypes.length).fill(false);
    homeTypesTemp[homeTypes.indexOf(home.homeType)] = true;
    setHomeTypeCheckedState(homeTypesTemp);
    var specialInfosTemp = new Array(specialInfos.length).fill(false);
    for (let i = 0; i < home.specialInfos.length; i++) {
      specialInfosTemp[specialInfos.indexOf(home.specialInfos[i])] = true;
    }
    setSpecialInfosCheckedState(specialInfosTemp);
    setMaxNights(home.limitations.maxNights);
    setMaxPersons(home.limitations.maxPersons);
    setLimitationsCheckedState([
      home.limitations.caravan,
      home.limitations.tent,
      home.limitations.car,
    ]);
    setSpecialRemarks(home.limitations.specialRemarks);
    var amenitiesTemp = new Array(amenities.length).fill(false);
    for (let i = 0; i < home.amenities.length; i++) {
      amenitiesTemp[
        amenities.map((x) => x[0]).indexOf(home.amenities[i])
      ] = true;
    }
    setAmenitiesCheckedState(amenitiesTemp);
    // setImages(home.imageLinks);
    setPrice(home.price);
    setShareContactDetails(home.shareContactDetails);
    setPublishNow(home.activeState);
    setDescription(home.description);
  }

  useEffect(() => {
    editMode && home && populateForm();
  }, [home]);

  const [currency] = useLocalStorageState("currency", {
    ssr: true,
    defaultValue: "EUR",
  });

  function ConnectedAccountWarning() {
    return (
      <Alert variant="warning" style={{ marginTop: 20 }}>
        <Alert.Heading>{t("Attention")}</Alert.Heading>
        <p>
          {t(
            "In order to publish the accommodation and to be able to accept bookings you first need to connect your account to Stripe and enter relevant banking information for payouts. You can connect your account after creating the accommodation, which will be saved for later publication."
          )}
        </p>
      </Alert>
    );
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Click here to use current location.")}
    </Tooltip>
  );

  function InfoButtonHomeProducts() {
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {t(
          "Check if you want to offer additional products or activities to the campers (e.g. breakfast, sailing, ...)."
        )}
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <i class="bi bi-info-circle"></i>
      </OverlayTrigger>
    );
  }

  function ErrorMessage() {
    return (
      <Modal show={showErrorMsg} onHide={handleCloseErrorMsg}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Error")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "Make sure to fill out all the required fields, including the images and the detailed area on the map."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseErrorMsg}>
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <h5>{t("Address")}</h5>
        <p>{t("Enter the address of the camping location.")}</p>
        <OverlayTrigger
          placement="bottom-end"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <InputGroup as={Col} controlid="street">
            <InputGroup.Text>{t("Street, Nr.")}</InputGroup.Text>
            <FormControl
              required
              value={street}
              id="street_number"
              onChange={(e) => setStreet(e.target.value)}
              minLength={3}
            />
            <Button onClick={() => setPinToCurrentLocation(true)}>
              <i
                className={"bi-pin-map"}
                style={{ color: "white", fontSize: 20 }}
              ></i>
            </Button>
          </InputGroup>
        </OverlayTrigger>
      </Row>
      <Row className="mb-3">
        <InputGroup as={Col} controlid="zip">
          <InputGroup.Text>{t("Zip")}</InputGroup.Text>
          <FormControl
            required
            type="number"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            minLength={3}
            id="postal_code"
          />
        </InputGroup>
        <InputGroup as={Col} controlid="city">
          <InputGroup.Text>{t("City")}</InputGroup.Text>
          <FormControl
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            minLength={2}
            id="city"
          />
        </InputGroup>
        <InputGroup as={Col} controlid="country">
          <Form.Control
            as="select"
            required
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            value={country}
            id="country"
          >
            {countryList.map((country) => (
              <option value={country} key={country}>
                {getFlagEmoji(country) +
                  " " +
                  countries.getName(country, i18n.resolvedLanguage)}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <Col style={{ height: 400 }}>
          <MapEdit
            marker={marker}
            setMarker={setMarker}
            polygon={polygon}
            setPolygon={setPolygon}
            setPolygonArea={setPolygonArea}
            setDistanceMarkerPolygon={setDistanceMarkerPolygon}
            pinToCurrentLocation={pinToCurrentLocation}
            pinToPolygon={pinToPolygon}
            pinToMarker={pinToMarker}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Button
            disabled={!markerIsValid(marker)}
            onClick={() => setPinToPolygon(true)}
          >
            <i
              className={"bi-pentagon"}
              style={{ color: "white", fontSize: 20 }}
            ></i>{" "}
            {t("Add detailed area")}
          </Button>
        </Col>
        <Col>
          <p style={{ marginTop: 8 }}>
            {t("Size of selected area")}:{" "}
            {numberWithCommas(roundToDecimals(polygonArea, 1))} m<sup>2</sup>
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <hr style={{ marginTop: 20 }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <h5>{t("Type")}</h5>
        <p>{t("Select the type which best describes the location.")}</p>
        <div>
          {homeTypes.map((homeType, position) => (
            <Form.Check
              required
              label={
                <div className="radioButtonLabel">
                  <p>
                    {getHomeTypeIcon(homeType, 30)}
                    <br />
                    {t("homeType." + homeType)}
                  </p>
                </div>
              }
              type="radio"
              id={homeType}
              name="homeType"
              key={homeType}
              checked={position === homeTypeCheckedState}
              onChange={() => setHomeTypeCheckedState(position)}
              className="radioButton"
            />
          ))}
        </div>
      </Row>
      <Row className="mb-3">
        <h5>{t("Features")}</h5>
        <p>
          {t(
            "Select all features which further describe the location (optional)."
          )}
        </p>
        <div>
          {specialInfos.map((specialInfo, position) => (
            <Form.Check
              label={
                <>
                  {getSpecialInfoIcon(specialInfo)}{" "}
                  {t("specialInfos." + specialInfo)}{" "}
                  {specialInfo === "homeProducts" ? (
                    <InfoButtonHomeProducts />
                  ) : null}
                </>
              }
              type="checkbox"
              id={specialInfo}
              key={specialInfo}
              checked={specialInfosCheckedState[position]}
              onChange={() =>
                setSpecialInfosCheckedState(
                  specialInfosCheckedState.map((item, index) =>
                    index === position ? !item : item
                  )
                )
              }
            />
          ))}
        </div>
      </Row>
      <Row className="mb-3">
        <h5>{t("Limitations")}</h5>
        <InputGroup as={Col} controlid="maxPersons">
          <InputGroup.Text>{t("Max. Persons")}</InputGroup.Text>
          <FormControl
            required
            value={maxPersons}
            type="number"
            min="1"
            max="99"
            onChange={(e) => setMaxPersons(e.target.value)}
          />
        </InputGroup>
        <InputGroup as={Col} controlid="maxNights">
          <InputGroup.Text>{t("Max. Nights")}</InputGroup.Text>
          <FormControl
            required
            value={maxNights}
            type="number"
            min="1"
            max="364"
            onChange={(e) => setMaxNights(e.target.value)}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <div>
          {limitations.map((limitation, position) => (
            <Form.Check
              label={
                <>
                  {" "}
                  {getLimitationIcon(limitation)}{" "}
                  {t("limitations." + limitation)}{" "}
                </>
              }
              type="switch"
              id={limitation}
              key={limitation}
              checked={limitationsCheckedState[position]}
              onChange={() =>
                setLimitationsCheckedState(
                  limitationsCheckedState.map((item, index) =>
                    index === position ? !item : item
                  )
                )
              }
            />
          ))}
        </div>
        <InputGroup
          as={Col}
          controlid="specialRemarks"
          style={{ marginTop: 10 }}
        >
          <InputGroup.Text>{t("Special Remarks")}</InputGroup.Text>
          <FormControl
            value={specialRemarks}
            onChange={(e) => setSpecialRemarks(e.target.value)}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <h5>{t("Amenities")}</h5>
        <p>
          {t(
            "Select all the available amenities for this place. For each amenity the price per night of your place increases by the amount corresponding to that amenity."
          )}
        </p>
        <div>
          {amenities.map((amenity, position) => (
            <Form.Check
              label={
                <>
                  {getAmenityIcon(amenity[0])}{" "}
                  {t("amenities." + amenity[0]) +
                    " (+" +
                    amenity[1] +
                    " " +
                    JSON.parse(localStorage.getItem("currency")) +
                    ")"}
                </>
              }
              type="switch"
              id={amenity[0]}
              key={amenity[0]}
              checked={amenitiesCheckedState[position]}
              onChange={() =>
                setAmenitiesCheckedState(
                  amenitiesCheckedState.map((item, index) =>
                    index === position ? !item : item
                  )
                )
              }
            />
          ))}
        </div>
      </Row>
      <Row className="mb-3">
        <h5>{t("Description")}</h5>
        <p>
          {t(
            "Add a meaningful description to your new place. Nearby attractions? Atmosphere? Sales of home made products? etc."
          )}
        </p>
        <InputGroup as={Col} controlid="description">
          <InputGroup.Text>{t("Description")}</InputGroup.Text>
          <FormControl
            required
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            minLength={50}
          />
        </InputGroup>
        {descriptionErrorMsg && (
          <p style={{ color: "red" }}>{t("Min. 50 Characters")}</p>
        )}
      </Row>
      <Row className="mb-3">
        <h5>{t("Images")}</h5>
        <p>
          {t("Select min. 1 image. The first image appears as cover image.")}
        </p>
        {/* <Form.Group as={Col} controlid="images">
                    <Form.Control 
                        required
                        type="file" 
                        multiple 
                        onChange={handleFileChange} />
                    </Form.Group> */}
        <DragAndDrop files={images} setFiles={setImages} maxFiles={5} />
      </Row>
      {editMode && (
        <Row>
          <Col>
            <p>
              <b>{t("Current images")}</b>
            </p>
            <p>
              {home &&
                home.thumbnailLinks.sort().map((imageLink, index) => {
                  return (
                    <Image
                      key={index}
                      src={imageLink}
                      height={100}
                      width={100}
                      style={{
                        borderRadius: 2,
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                    />
                  );
                })}
            </p>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <h5>{t("Price")}</h5>
        <p>
          {t(
            "During the pilot phase of the App, the price per night is set to 10 %@ per default.",
            { val: currency }
          )}
        </p>
        <InputGroup as={Col} controlid="price">
          <InputGroup.Text>{currency}</InputGroup.Text>
          <Form.Control
            required
            type="number"
            min="1"
            max="999"
            value={price / 100}
            onChange={(e) => setPrice(e.target.value)}
            disabled
            readOnly
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <Col>
          <hr style={{ marginTop: 20 }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <h5>{t("Share contact details")}</h5>
        <p>
          {t(
            "Select if your contact details (name & email) should be shared with guests who booked the accommodation. Guests will only be able to see the details after the payment confirmation."
          )}
        </p>
        <InputGroup as={Col} controlid="shareContactDetails">
          <Form.Check
            label={t("Share contact details (recommended)")}
            type="switch"
            id="shareContactDetails"
            checked={shareContactDetails}
            onChange={(e) => setShareContactDetails(e.target.value)}
          />
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <h5>{t("Publish")}</h5>
        <p>
          {t(
            "Every place has to pass the review process before it will be published. This should not take longer than a few minutes."
          )}
        </p>
        <InputGroup as={Col} controlid="publishNow">
          <Form.Check
            label={t(
              "Publish now (this can also be changed later in the settings)"
            )}
            type="switch"
            id="publishNow"
            checked={publishNow}
            onChange={(e) => setPublishNow(e.target.value)}
            disabled={!isConnectedAccount}
          />
        </InputGroup>
      </Row>
      <Row>
        <Col>{!isConnectedAccount && ConnectedAccountWarning()}</Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <hr style={{ marginTop: 20 }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <InputGroup as={Col}>
          <Button
            variant="primary"
            type="submit"
            disabled={submittingInProgress}
          >
            {submittingInProgress ? (
              <Spinner animation="border" size="sm" />
            ) : home ? (
              t("Update")
            ) : (
              t("Create")
            )}
          </Button>
        </InputGroup>
      </Row>
      <Row className="mb-3">
        <div style={{ margin: "20px 0px 0px 0px" }}>
          <ProgressBar now={progress} />
        </div>
      </Row>
      <ErrorMessage />
    </Form>
  );
}

export default withTranslation()(HomeForm);
