import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ExploreView.css";

// Components
import HomeStackList from "./HomeStackList";
import Map from "../../shared/Map.js";

// Services
import {
  styles,
  useWindowSize,
  getHomeTypeIcon,
  getSpecialInfoIcon,
  getAmenityIcon,
  isEqual,
} from "../../../other/Utilities";

function ExploreSortBar({ searchParams, setSearchParams }) {
  const { t } = useTranslation();
  const sortTemplate = {
    homeType: ["farm", "parking"],
    specialInfos: ["lake", "mountain", "homeProducts"],
    amenities: ["toilet", "shower"],
  };

  function getIcon(parentKey, value) {
    switch (parentKey) {
      case "homeType":
        return getHomeTypeIcon(value);
      case "specialInfos":
        return getSpecialInfoIcon(value);
      case "amenities":
        return getAmenityIcon(value);
      default:
        return;
    }
  }

  return (
    <Container fluid id="sortBar">
      <Row className="x-scroller">
        {/*<Col md="auto" sm="auto" xs="auto">
          <Button variant="secondary" className={"searchParamButton"}>
            {t("Sort by: Newest")}
          </Button>
        </Col>*/}
        <Col>
          {Object.entries(sortTemplate).map(([parentKey, parentValue]) => {
            return parentValue.map((value) => {
              return (
                <Button
                  key={value}
                  //variant={Array.from(searchParams.entries()).some(e => isEqual(e, [parentKey, value])) ? "secondary" : "outline-secondary" }
                  variant="secondary"
                  className={
                    Array.from(searchParams.entries()).some((e) =>
                      isEqual(e, [parentKey, value])
                    )
                      ? "searchParamButtonSelected"
                      : "searchParamButton"
                  }
                  onClick={() => {
                    var newValue = Array.from(searchParams.entries());
                    if (newValue.some((e) => isEqual(e, [parentKey, value]))) {
                      // Remove the value from the list
                      newValue = newValue.filter(function (el, index, arr) {
                        return !isEqual(el, [parentKey, value]);
                      });
                    } else {
                      // Add the value to the list
                      newValue.push([parentKey, value]);
                    }
                    setSearchParams(newValue);
                  }}
                >
                  {getIcon(parentKey, value)} {t(parentKey + "." + value)}
                </Button>
              );
            });
          })}
        </Col>
      </Row>
    </Container>
  );
}

function ExploreMain({ searchParams }) {
  const { t } = useTranslation();
  const [parentHomes, setParentHomes] = useState([]);
  const [width] = useWindowSize();
  const [isShowingMap, setShowingMap] = useState(false);
  const homeListCol = document.getElementById("homeListCol");
  const mapCol = document.getElementById("mapCol");

  useEffect(() => {
    if (width === 0) return;
    if (width > 1150) {
      homeListCol.style.display = "block";
      mapCol.style.display = "block";
    } else {
      homeListCol.style.display = isShowingMap ? "none" : "block";
      mapCol.style.display = isShowingMap ? "block" : "none";
    }
  }, [width]);

  useEffect(() => {
    if (width > 0 && width <= 1150) {
      homeListCol.style.display = isShowingMap ? "none" : "block";
      mapCol.style.display = isShowingMap ? "block" : "none";
    }
  }, [isShowingMap]);

  return (
    <Container fluid style={styles.container}>
      <Row id="floatingMapButtonCol" style={styles.row}>
        <Col style={styles.col}>
          <span
            id="floatingMapButton"
            onClick={() => setShowingMap(!isShowingMap)}
          >
            {isShowingMap ? t("Hide Map") : t("Show Map")}{" "}
            <i className="bi-map" style={{ fontSize: 20 }}></i>
          </span>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col id="homeListCol" style={styles.col}>
          <HomeStackList
            searchParams={searchParams}
            setParentHomes={setParentHomes}
          />
        </Col>
        <Col id="mapCol" style={styles.col}>
          <Map homes={parentHomes} zoomedMap={false} />
        </Col>
      </Row>
    </Container>
  );
}

function ExploreView() {
  let [searchParams, setSearchParams] = useSearchParams();
  return (
    <Container fluid style={styles.container}>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <ExploreSortBar
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <ExploreMain searchParams={searchParams} />
        </Col>
      </Row>
    </Container>
  );
}

export default ExploreView;
