import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

function BackButton({ t }) {
  const navigate = useNavigate();

  return (
    <Button
      variant="light"
      onClick={() => navigate(-1)}
      style={{ marginBottom: 10 }}
    >
      <i className="bi-chevron-left"></i>
      {t("back")}
    </Button>
  );
}

export default withTranslation()(BackButton);
