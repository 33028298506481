import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import validator from "validator";
import countries from "i18n-iso-countries";
import axios from "axios";

// Services
import {
  registerWithEmailAndPassword,
  updateUserData,
} from "../../../services/Firebase";
import { getFlagEmoji } from "../../../other/Utilities";
import { countryList } from "../../../other/Constants";

// Views
import ErrorModal from "../../shared/ErrorModal";

function UserForm({ t, i18n, editMode, userData }) {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Form Data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [country, setCountry] = useState("CH");
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [userFacingError, setUserFacingError] = useState({
    title: "",
    message: "",
    show: false,
  });

  const getGeoInfo = () => {
    // Get country information for current user session
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setCountry(response.data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    countries.registerLocale(
      require("i18n-iso-countries/langs/" + i18n.resolvedLanguage + ".json")
    );
  }, [i18n.language]);

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email);
      const m = userData.birthDate.toDate();
      var dateString =
        m.getUTCFullYear() +
        "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + m.getUTCDate()).slice(-2);
      setBirthDate(dateString);
      setCountry(userData.country);
    }
  }, [userData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (event.currentTarget.checkValidity() === false) {
      return;
    } else {
      if (validator.isEmail(email) && password === passwordConfirm) {
        setLoading(true);
        if (editMode) {
          await updateUserData(userData.uid, {
            firstName: firstName,
            lastName: lastName,
            birthDate: new Date(birthDate),
            country: country,
          });
        } else {
          const userData = {
            firstName: firstName,
            lastName: lastName,
            birthDate: new Date(birthDate),
            country: country,
            email: email,
            registrationDate: new Date(),
            language: i18n.resolvedLanguage,
            emailSubscription: true,
          };
          await registerWithEmailAndPassword(
            email,
            password,
            userData,
            setUserFacingError
          );
        }
        setLoading(false);
      } else {
        return;
      }
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3" xs={1}>
          <h5>{t("Name")}</h5>
          <InputGroup as={Col} style={{ marginBottom: 10 }}>
            <InputGroup.Text>{t("First name")}</InputGroup.Text>
            <FormControl
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              id="firstName"
              minLength={2}
            />
          </InputGroup>
          <InputGroup as={Col}>
            <InputGroup.Text>{t("Last name")}</InputGroup.Text>
            <FormControl
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              id="lastName"
              minLength={2}
            />
          </InputGroup>
        </Row>
        <Row className="mb-3">
          <h5>{t("Email")}</h5>
          <InputGroup as={Col}>
            <InputGroup.Text>{t("Email")}</InputGroup.Text>
            <FormControl
              required
              type="email"
              value={email}
              isValid={validator.isEmail(email)}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              minLength={5}
              disabled={editMode}
            />
            <Form.Control.Feedback type="invalid">
              {t("Email is invalid!")}
            </Form.Control.Feedback>
          </InputGroup>
          {/* TODO: Add Change Email functionality in editMode */}
        </Row>
        <Row className="mb-3" xs={1}>
          <h5>{t("Password")}</h5>
          <InputGroup as={Col} style={{ marginBottom: 10 }}>
            <InputGroup.Text>{t("Password")}</InputGroup.Text>
            <FormControl
              required
              type="password"
              value={password}
              isValid={
                validator.isStrongPassword(password) &&
                password === passwordConfirm
              }
              minLength={6}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              disabled={editMode}
            />
            <Form.Control.Feedback type="invalid">
              {t("Password is too weak!")}
            </Form.Control.Feedback>
          </InputGroup>
          <InputGroup as={Col}>
            <InputGroup.Text>{t("Password (2x)")}</InputGroup.Text>
            <FormControl
              required
              type="password"
              value={passwordConfirm}
              isValid={
                validator.isStrongPassword(password) &&
                password === passwordConfirm
              }
              minLength={6}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              id="password2"
              disabled={editMode}
            />
            <Form.Control.Feedback type="invalid">
              {t("Passwords don't match!")}
            </Form.Control.Feedback>
          </InputGroup>
          {/* TODO: Add Change Password functionality in editMode */}
        </Row>
        <Row className="mb-3">
          <h5>{t("Birthdate")}</h5>
          <InputGroup as={Col}>
            <InputGroup.Text>{t("Birthdate")}</InputGroup.Text>
            <FormControl
              required
              type="date"
              name="birthdate"
              placeholder="BirthDate"
              value={birthDate}
              max={new Date().toISOString().split("T")[0]}
              min={new Date("01/01/1900").toISOString().split("T")[0]}
              onChange={(e) => setBirthDate(e.target.value)}
              id="birthdate"
            />
          </InputGroup>
        </Row>
        <Row className="mb-3">
          <h5>{t("Country")}</h5>
          <InputGroup as={Col}>
            <InputGroup.Text>{t("Country")}</InputGroup.Text>
            <Form.Control
              as="select"
              required
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              value={country}
              id="country"
            >
              {countryList.map((country) => (
                <option value={country} key={country}>
                  {getFlagEmoji(country) +
                    " " +
                    countries.getName(country, i18n.resolvedLanguage)}
                </option>
              ))}
              {/*<hr key="hr" />*/}
              {Object.entries(
                countries.getNames(i18n.resolvedLanguage, {
                  select: "official",
                })
              ).map(
                (country) =>
                  !countryList.includes(country[0]) && (
                    <option value={country[0]} key={country[0]}>
                      {getFlagEmoji(country[0]) + " " + country[1]}
                    </option>
                  )
              )}
            </Form.Control>
          </InputGroup>
        </Row>
        {!editMode && (
          <Row className="mb-3">
            <InputGroup as={Col}>
              <Form.Check
                required
                label={t("By registering I agree to the following:")}
                type="checkbox"
                id={"termsAndConditions"}
                key={"termsAndConditions"}
                checked={termsAndConditionsAccepted}
                onChange={() =>
                  setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
                }
              />
              <span style={{ marginLeft: 5 }}>
                <Link to={"/privacy-policy"}>{t("Privacy Policy")}</Link>,{" "}
                <Link to={"/terms-of-service"}>{t("Terms of Service")}</Link>
              </span>
            </InputGroup>
          </Row>
        )}
        <Row className="mb-3">
          <InputGroup as={Col}>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : editMode ? (
                t("Update")
              ) : (
                t("Register")
              )}
            </Button>
          </InputGroup>
        </Row>
      </Form>
      <ErrorModal
        userFacingError={userFacingError}
        setUserFacingError={setUserFacingError}
      />
    </>
  );
}

export default withTranslation()(UserForm);
